import { bindActionCreators } from 'redux';
import * as actions from './actions';

export function mapStateToProps(state) {
  return {
    users: state.users,
    customers: state.customers,
    providers: state.providers,
    vouchers: state.vouchers,
    products: state.products,
    static: state.static,
    transactions: state.transactions,
    transactionsVoucher: state.transactionsVoucher,
    categorys: state.categorys,
    notifications: state.notifications,
    loder: state.loder
  }
}

export function mapDispachToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}
