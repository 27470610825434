import {
 GET_ALL_NOTIFICATION
  } from '../actions/types';
  
  const initialState = {
    notifications: [],
    copynotifications: [],
    data: []
  };
  
  const notificationReducer = (state = initialState, action) => {
    const payload = action.payload
 
    switch (GET_ALL_NOTIFICATION) {
      case GET_ALL_NOTIFICATION:
        return { ...state, notifications: payload, copynotifications: payload };
  
     
  
      default:
        return state;
    }
  };
  
  export default notificationReducer;
  