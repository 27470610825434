import {
    GET_ALL_CATEGORY,
    GET_ONE_CATEGORY,
  } from './types';
  
  import axios from 'axios';
  const URL = process.env.REACT_APP_API_URL;
  
  const handlePost = (body, endpoint, headers = {}) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    headers = {
      ...headers,
      "x-request-token": token
    }
    return new Promise((resolve, reject) => {
      axios.post(`${URL}${endpoint}`, body, {
        headers
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  
  }
  
  const handleGet = (endpoint, headers = {}) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    headers = {
      ...headers,
      "x-request-token": token
    }
    return new Promise((resolve, reject) => {
      axios.get(`${URL}${endpoint}`, {
        headers
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
 
  export const getAllcategory = (data=[]) => {
    return function(dispatch) {
      handleGet('/getallcategory')
      .then(res => {
        //console.log(res.data)
        dispatch({ type: GET_ALL_CATEGORY, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
    }
  };
  
  export const getOnecategory = (data) => {
    return function(dispatch) {
      handleGet(`/getonecategory/${data}`)
      .then(res => {
        dispatch({ type: GET_ONE_CATEGORY, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
    }
  };
  
  function formDataToJson(formData) {
    const obj = {};
    formData.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  export const updateCatagory = (data, headers = {}) => {
  return function(dispatch) {

    let dataObject = {};
    if (data instanceof FormData) {
      dataObject = formDataToJson(data);
    } else {
      dataObject = data;
    }

    headers['Content-Type'] = 'application/json';

    handlePost(dataObject, `/updatecategory`, headers)
      .then(res => {
        console.log("updated category", res);
      })
      .catch(e => {
        console.log(e);
      });
  };
};
  
  export const addcategory = (data, headers = {}) => {
    return function(dispatch) {
      handlePost(data, `/add_new_categories`, headers)
      .then(res => {
        alert("Category Added ")
        console.log(res.data)
      })
      .catch(e => {
        console.log(e)
      })
    }
  };
 