const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'cid',
        headerName: 'CID',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'pid',
        headerName: 'PID',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'subject',
        headerName: 'Subject',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'message',
        headerName: 'Message',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'create_date',
        headerName: 'Create Date',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
  
];

export default columns;