import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useNavigate , useLocation } from "react-router-dom";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Container from '@mui/material/Container';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import axios from 'axios';
import { Navigate } from 'react-router-dom';
const URL = process.env.REACT_APP_API_URL;


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Vendoapp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const checkLocalStorage = () => {
  return localStorage.getItem("uid") ? true : false;
};

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: "8em !important",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: "1em !important",
    backgroundColor: "#f50057 !important",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: "1em !important",
  },
  submit: {
    margin: '1em 0 1em 0 !important',
  },
}));

const ConfirmPassword = (props) => {
 
  const [password, setPassword] = useState("");
  const [cpassword , setCPassword] = useState("");

  const location = useLocation()

  const handlePost = (body, endpoint) => {

    if(password === "" || password === null){
      alert("Please enter a new password.")
      return
    }
    if(cpassword === "" || cpassword === null){
      alert("Please confirm your password.")
      return
    }

    if(password != cpassword){
      alert("Pasword dosn't match.")
      return
    }

    console.log("body", body);

    axios.post(`${URL}${endpoint}`, body)
      .then(res => {
        let data = res.data
        if (data.status) {
          console.log('get data from save pasword --->', data);
          alert("Password changed successfully!")
          navigate('/login')
        } else {
          console.log('get data from not save pasword --->', data);
          alert(data.msg)
        }
      })
      .catch(e => {
        console.log("catch password save",e)
        alert(e.message)
      })
  }
 
  const classes = useStyles();
  let navigate = useNavigate();

  if (checkLocalStorage()) {
    window.location = "/dashboard"
    // return null
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enter your new password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newpassword"
            label="New Password"
            name="newpassword"
            onChange={(e) => {
             
              setPassword(e.currentTarget.value)
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type="confirmpassword"
            id="password"
            onChange={(e) => {
              setCPassword(e.currentTarget.value)
            }}
          
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              handlePost({
                    email: localStorage.getItem('email'),
                    newPassword : password,
                    otp : location.state.otpNumber
                  }, "/users/resetOtpPassword")

            }}
          >
            Submit
          </Button>
        </form>
      </div>
      <Box mt={15}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default ConfirmPassword
