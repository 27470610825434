import {
  GET_ALL_USERS,
  GET_ONE_USER,
  SEARCH_USERS
} from '../actions/types';


const initialState = {
  users: [],
  copyUsers: [],
  data: []
};

const userReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, users: payload, copyUsers: payload };

    case GET_ONE_USER:
      return { ...state, data: payload };

    case SEARCH_USERS:
      if(payload.length <= 0){
        return { ...state, users: state.copyUsers }
      }

      const regex = new RegExp(payload, 'i')
      let filteredUsers = []
      state.copyUsers.forEach(e => {
        if(regex.test(e.name)){
          filteredUsers.push(e)
        }
      })

      return { ...state, users: filteredUsers }

    default:
      return state;
  }
};

export default userReducer;
