import {
  GET_ALL_CUSTOMERS,
  GET_ONE_CUSTOMER,
  SEARCH_CUSTOMERS
} from '../actions/types';

const initialState = {
  customers: [],
  copyCustomers: [],
  data: []
};

const customerReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case GET_ALL_CUSTOMERS:
      return { ...state, customers: payload, copyCustomers: payload };

    case GET_ONE_CUSTOMER:
      return { ...state, data: payload };

    case SEARCH_CUSTOMERS:
      if(payload.length <= 0){
        return { ...state, customers: state.copyCustomers }
      }

      const regex = new RegExp(payload, 'i')
      let filteredUsers = []
      state.copyCustomers.forEach(e => {
        if (regex.test(e.name)) {
          filteredUsers.push(e)
        }
      })

      return { ...state, customers: filteredUsers }

    default:
      return state;
  }
};

export default customerReducer;
