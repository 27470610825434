import { configureStore } from '@reduxjs/toolkit'
import ReduxThunk from 'redux-thunk';

// root reducer
import rootReducer from './reducers';

/**
 * Redux dev tools.
 */

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    ReduxThunk,
  ],
  enhancers: [window.devToolsExtension ? window.devToolsExtension() : f => f]
})

export default store;
