import {
  GET_ALL_PROVIDERS,
  GET_ONE_PROVIDER,
  DEACTIVATE_PROVIDER,
  SEARCH_PROVIDERS,
  DISABLE_LODER,ENABLE_LODER,ADDPRFIELD,EDITPRFIELD,DELETEPRFIELD
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  console.log("====handlePost endpoint======>",URL+endpoint)
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  console.log("====endpoint======>",endpoint)
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const searchProviders = (name) => {
  return { type: SEARCH_PROVIDERS, payload: name }
};

export const getAllProviders = (data=[]) => {
  return function(dispatch) {
    handleGet('/providers/getAllUsers')
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ALL_PROVIDERS, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const getOneProvider = (data) => {
  return function(dispatch) {
    handleGet(`/providers/getOneUserweb/${data}`)
    .then(res => {
      console.log("getOneProvider======>",res.data)
      dispatch({ type: GET_ONE_PROVIDER, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const updateProvider = (data, headers = {}) => {
  return function(dispatch) {
    dispatch({ type: ENABLE_LODER });
    handlePost(data, `/providers/updateUser`, headers)
    .then(res => {
      if(res.data.status){
        alert("Updated Provider")
      }else{
        alert(res.data.msg)
      }
     
      console.log(res.data)
      dispatch({ type: DISABLE_LODER });
    })
    .catch(e => {
      console.log(e)
      dispatch({ type: DISABLE_LODER });
    })
  }
};

export const addProvider = (data, headers = {}) => {
  return function(dispatch) {
    handlePost(data, `/providers/add_user`, headers)
    .then(res => {
      if(res.data.status === true){
      alert("Added Provider")
      console.log('added provider --->',res.data)
      dispatch({ type: DISABLE_LODER });
      }else{
      alert(res.data.msg)
      console.log('provider is not added --->',res.data)
      dispatch({ type: DISABLE_LODER });
      }
    
    })
    .catch(e => {
      console.log(e)
      alert(e.res.data.msg)
      dispatch({ type: DISABLE_LODER });
    })
  }
};

export const deactivateProvider = (data) => {
  return { type: DEACTIVATE_PROVIDER, payload: { data } };
};

export const addPRFieldProvider=(providerId,data,headers={})=>{
  
  return function(dispatch) {
    dispatch({ type: ENABLE_LODER });
    handlePost(data, `/providers/addPRFeedData`, headers)
    .then(res => {
      dispatch({ type: DISABLE_LODER })
      console.log('provider --->',providerId,res.data.data.PRFeedData)
      alert(res.data.msg)
      if(res.data.status === true){
        //alert("PR Feed Added")
      dispatch({ type: ADDPRFIELD, payload: res.data.data })
      //getOneProvider(providerId)
    }
      else{
        alert(res.data.msg)
      }
    })
    .catch(e => {
      console.log(e)
      dispatch({ type: DISABLE_LODER })
    })
  }
}