import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { notificationStructure } from '../../data_formats';

const NotificationTable = (props) => {
  React.useEffect(() => {
    props.getAllnotification();
    console.log('4');
   console.log(props);
  }, [])
  return (
    <>
   
    <Table
        title={"Notification"}
        pageTitle={"Notification"}
        columns={notificationStructure}
        rows={props.notifications.notifications}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"notificationStructure"}
        dataKey={"notifications"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(NotificationTable);