import { ENABLE_LODER, DISABLE_LODER } from "../actions/types";

const initialState = {
    loderState: false
}


const loderReducer = (state=initialState , action) =>{
    console.log("action", action);
    switch (action.type){
        case ENABLE_LODER :
            return {...state , loderState: true};
        
        case DISABLE_LODER :
            return {...state , loderState: false};

        default:
            return state
    }
}

export default loderReducer;