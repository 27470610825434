import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { voucherStructure } from '../../data_formats';

const VoucherTable = (props) => {
  React.useEffect(() => {

    let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
    let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

    if ([2].includes(priority)) {
      props.getAllVoucherByProvider(user.id)
    } else {
      props.getAllVouchers()
    }

  }, [])
  return (
    <>
      <Table
        title={"Vouchers"}
        pageTitle={"Voucher"}
        columns={voucherStructure}
        rows={props.vouchers.vouchers}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"voucherStructure"}
        dataKey={"vouchers"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(VoucherTable);