const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    {
        field: 'providerName',
        headerName: 'Provider name*',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'address',
        headerName: 'Address',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'postcode',
        headerName: 'Postal Code',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'number',
        headerName: 'Number',
        inputType: 'text',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'region',
        headerName: 'Region',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'branch',
        headerName: 'Branch',
        inputType: 'select9',
        hide: false,
        width: 150,
        editable: false,
        data9: 'Branch',
    },
    {
        field: 'email',
        headerName: 'Email*',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'password',
        headerName: 'Password',
        inputType: 'password',
        width: 150,
        hide: true,
        editable: false,
    },
    {
        field: 'verifyPassword',
        headerName: 'Enter password again',
        inputType: 'password',
        hide: true,
        width: 150,
        editable: false,
    },
    // {
    //     field: 'category',
    //     headerName: 'Category',
    //     inputType: 'select',
    //     hide: false,
    //     width: 150,
    //     editable: false,
    //     data:'Provider',
    // },
    {
        field: 'community',
        headerName: 'Community',
        inputType: 'select2',
        hide: false,
        width: 150,
        editable: false,
        data4:'Community',
    },
    {
        field: 'telephone',
        headerName: 'Telephone',
        inputType: 'text',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'domain',
        headerName: 'Website',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'description',
        headerName: 'Description',
        inputType: 'text',
        width: 250,
        editable: false,
    },
    // {
    //     field: 'Imprint',
    //     headerName: 'Imprint',
    //     inputType: 'text',
    //     width: 250,
    //     editable: false,
    // },
    {
        field: 'openTime',
        headerName: 'Order Start time',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    // {
    //     field: 'closeTime',
    //     headerName: 'Order close time',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    // {
    //     field: 'orderStartDay',
    //     headerName: 'Order Start Date',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    // {
    //     field: 'orderEndDay',
    //     headerName: 'Order End Date',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    {
        field: 'deliveryCircle',
        headerName: 'Delivery Circle',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'deliveryCost',
        headerName: 'Delivery Cost',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'deliveryApproxTime',
        headerName: 'Delivery Time (min)',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'minOrderCost',
        headerName: 'Minimum Order Cost',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'logo',
        headerName: 'Provider Logo',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    // {
    //     field: 'subject_line',
    //     headerName: 'PR Text',
    //     sortable: true,
    //     inputType: 'text',
    //     width: 180,
    //     isDisabled: false
    // },
    // {
    //     field: 'subject_line1',
    //     headerName: 'Add PR Text',
    //     sortable: true,
    //     inputType: 'multipleText',
    //     width: 180,
    //     isDisabled: false
    // },
    {
        field: 'PRFeedData',
        headerName: 'Add PR Feed',
        sortable: true,
        inputType: 'PRFeed',
        width: 180,
        isDisabled: false
    },
 
    {
        field: 'companyPresentation',
       // headerName: 'Company Presentation Image',
        headerName: 'Company Presentation',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'companyPresentationpdf',
    //     headerName: 'Company Presentation PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    
    {
        field: 'companyPresentationStartDay',
        headerName: 'Company Presentation start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'companyPresentationEndDay',
        headerName: 'Company Presentation end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'companyPresentationStartDay'
    },


    {
        inputType: 'heading',
        title:"Company presentation for Welcome page"
    },
    {
        field: 'company_presentation_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'company_presentation_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.company_presentation_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'company_presentation_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'company_presentation_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'company_presentation_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'company_presentation_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'company_presentation_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'companyWelcomeStartDay',
        headerName: 'Company Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'companyWelcomeEndDay',
        headerName: 'Company Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'companyWelcomeStartDay',
    },
    {
        field: 'jobAdvertisement',
        //headerName: 'Job Advertisement Image',
        headerName: 'Job Advertisement',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'jobAdvertisementpdf',
    //     headerName: 'Job Advertisement PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    
    
    {
        field: 'jobAdvertisementStartDay',
        headerName: 'Job Advertisement start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'jobAdvertisementEndDay',
        headerName: 'Job Advertisement end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate: 'jobAdvertisementStartDay',
    },
    {
        inputType: 'heading',
        title:"Job Advertisement for Welcome page"
    },
    {
        field: 'job_advertisement_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'job_advertisement_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.job_advertisement_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'job_advertisement_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'job_advertisement_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'job_advertisement_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'job_advertisement_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'job_advertisement_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'jobAdvertisementWelcomeStartDay',
        headerName: 'Job Advertisement Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'jobAdvertisementWelcomeEndDay',
        headerName: 'Job Advertisement Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'jobAdvertisementWelcomeStartDay'
    },
    {
        field: 'flyer',
        //headerName: 'Flyer Image',
        headerName: 'Flyer',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'flyerpdf',
    //     headerName: 'Flyer PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    {
        field: 'flyerStartDay',
        headerName: 'Flyer start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'flyerEndDay',
        headerName: 'Flyer end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'flyerStartDay'
    },
    {
        inputType: 'heading',
        title:"Flyer for Welcome page"
    },
    {
        field: 'flyer_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'flyer_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.flyer_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'flyer_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'flyer_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'flyer_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'flyer_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'flyer_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'flyerWelcomeStartDay',
        headerName: 'Flyer Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'flyerWelcomeEndDay',
        headerName: 'Flyer Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'flyerWelcomeStartDay'
    },
    {
        field: 'advertisement',
        headerName: 'Advertisement',
        //headerName: 'Advertisement Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'advertisementpdf',
    //     headerName: 'Advertisement PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    {
        field: 'advertisementStartDay',
        headerName: 'Advertisement start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'advertisementEndDay',
        headerName: 'Advertisement end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'advertisementStartDay'
    },
    {
        inputType: 'heading',
        title:"Advertisement for Welcome page"
    },
    {
        field: 'advertisement_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'advertisement_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.advertisement_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'advertisement_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'advertisement_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'advertisement_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'advertisement_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'advertisement_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'advertisementWelcomeStartDay',
        headerName: 'Advertisement Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'advertisementWelcomeEndDay',
        headerName: 'Advertisement Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'advertisementWelcomeStartDay'
    },
    {
        field: 'menu',
        //headerName: 'Menu Image',
        headerName: 'Menu',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'menupdf',
    //     headerName: 'Menu PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    {
        field: 'menuStartDay',
        headerName: 'Menu start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'menuEndDay',
        headerName: 'Menu end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'menuStartDay'
    },
    {
        inputType: 'heading',
        title:"Menu for Welcome page"
    },
    {
        field: 'menu_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'menu_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.menu_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'menu_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'menu_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'menu_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'menu_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'menu_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'menuWelcomeStartDay',
        headerName: 'Menu Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'menuWelcomeEndDay',
        headerName: 'Menu Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'menuWelcomeStartDay'
    },
    {
        field: 'info',
        //headerName: 'Info Image',
        headerName: 'Info',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'infopdf',
    //     headerName: 'Info PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    {
        field: 'infoStartDay',
        headerName: 'Info start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'infoEndDay',
        headerName: 'Info end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'infoStartDay'
    },
    {
        inputType: 'heading',
        title:"Info presentation for Welcome page"
    },
    {
        field: 'info_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'info_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.info_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'info_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'info_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'info_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'info_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'info_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'infoWelcomeStartDay',
        headerName: 'Info Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'infoWelcomeEndDay',
        headerName: 'Info Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'infoWelcomeStartDay'
    },
    {
        field: 'event',
        //headerName: 'Event Image',
        headerName: 'Event',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png","pdf"]
    },
    // {
    //     field: 'eventpdf',
    //     headerName: 'Event PDF',
    //     inputType: 'pdfFile',
    //     width: 150,
    //     editable: false,
    //     fileType: ["pdf"]
    // },
    {
        field: 'eventStartDay',
        headerName: 'Event start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'eventEndDay',
        headerName: 'Event end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:"eventStartDay"
    },
    {
        inputType: 'heading',
        title:"Event for Welcome page"
    },
    {
        field: 'event_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'event_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.event_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'event_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'event_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'event_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'event_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'event_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'eventWelcomeStartDay',
        headerName: 'Event Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'eventWelcomeEndDay',
        headerName: 'Event Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'eventWelcomeStartDay'
    },
    {
        field: 'advertisingVideo',
        headerName: 'Advertising Video',
        inputType: 'videoFile',
        width: 150,
        editable: false,
        fileType: ["mp4"]
    },
    {
        field: 'advertisingVideoStartDay',
        headerName: 'Advertising Video start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'advertisingVideoEndDay',
        headerName: 'Advertising Video end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'advertisingVideoStartDay'
    },
    {
        inputType: 'heading',
        title:"Advertisement Video for Welcome page"
    },
    {
        field: 'advertising_video_subject_line',
        headerName: 'Subject for Welcome Page',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'advertising_video_feed_show_status',
        headerName: 'Post in Feed',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.advertising_video_feed_show_status==1 ? "Yes" : "No"
    },
    {
        field: 'advertising_video_input_text',
        headerName: 'Details...',
        sortable: true,
        inputType: 'multiText',
        width: 150,
        isDisabled: false
    },
    {
        field: 'advertising_video_picture_1',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'advertising_video_picture_2',
        headerName: 'Add Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'advertising_video_like_count',
        headerName: 'Add Image',
        inputType: 'totalLiked',
        
    },
    {
        field: 'advertising_video_wishlist_count',
        headerName: 'Add Image',
        inputType: 'totalWishlist',
        
    },
    {
        field: 'advertisingVideoWelcomeStartDay',
        headerName: 'Advertising Video Welcome start day',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'advertisingVideoWelcomeEndDay',
        headerName: 'Advertising Video Welcome end day',
        inputType: 'date',
        width: 150,
        editable: false,
        minimumDate:'advertisingVideoWelcomeStartDay'
    },
    {
        field: 'paypalMode',
        headerName: 'Allow Paypal',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.paypalMode ? "Yes" : "No"
    },
    {
        field: 'paypalClientId',
        headerName: 'Enter Paypal Client Id',
        inputType: 'text',
        width: 150,
        editable: false,
        hideInput: true,
        hide: true
    },
    {
        field: 'paypalClientSecret',
        headerName: 'Enter Paypal Client Secret',
        inputType: 'text',
        width: 150,
        hide: true,
        editable: false,
        hideInput: true
    },
    {
        field: 'cashMode',
        headerName: 'Allow Cash',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.cashMode ? "Yes" : "No"
    },
    {
        field: 'Abholung',
        headerName: 'Abholung',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.Abholung ? "Yes" : "No"
    },
    {
        field: 'Lieferung',
        headerName: 'Lieferung',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.Lieferung ? "Yes" : "No"
    },
    {
        field: 'iswelcome',
        headerName: 'Welcome Page',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.iswelcome ? "Yes" : "No"
    },
    {
        field: 'availability',
        headerName: 'Open',
        inputType: 'switch',
        width: 120,
        isDisabled: false,
        valueGetter: (params) => params.row.availability ? "Yes" : "No"
    },
    {
        field: 'emailVerified',
        headerName: 'Email verified',
        inputType: 'switch',
        width: 120,
        isDisabled: true,
        valueGetter: (params) => params.row.emailVerified ? "Yes" : "No"
    },
    {
        field: 'deactivate',
        headerName: 'Deactivated',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.deactivate ? "Yes" : "No"
    },
];

export default columns;