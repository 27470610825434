const notRequired = {
    Customer: [
        "id",
        "street",
        "email",
        "postCode",
        "place",
        "region",
        "deliveryNote",
        "voucherTaken",
        "deactivate",
        "emailVerified"
    ],
    Provider: [
        "id",
        "mobile",
        "emailVerified",
        "domain",
        "companyPresentation",
        "companyPresentationStartDay",
        "companyPresentationEndDay",
        "advertisement",
        "advertisementStartDay",
        "advertisementEndDay",
        "flyer",
        "flyerStartDay",
        "flyerEndDay",
        "jobAdvertisement",
        "jobAdvertisementStartDay",
        "jobAdvertisementEndDay",
        "menu",
        "menuStartDay",
        "menuEndDay",
        "info",
        "infoStartDay",
        "infoEndDay",
        "event",
        "eventStartDay",
        "eventEndDay",
        "advertisingVideo",
        "advertisingVideoStartDay",
        "advertisingVideoEndDay",
        "deactivate",
        "deliveryCost",
        "minOrderCost",
        "paypalClientId",
        "paypalClientSecret",
        "openTime",
        "closeTime",
        "orderStartDay",
        "orderEndDay",
        "deliveryApproxTime"
    ],
    Admin: [
        "id"
    ],
    Category: [
        "id"
    ],
    Product: [
        "id",
        "productImage",
        "size",
        "ingredients"
    ],
    Voucher: [
        "id",
        "voucherTaken",
        "voucherRedeemed",
        "deactivate"
    ]
}

export default notRequired