const columns = [
    {
        field: 'howItWorks',
        headerName: 'How It Works',
        inputType: 'text',
        width: 160,
        editable: false,
    },
    {
        field: 'contact',
        headerName: 'Contact',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    // {
    //     field: 'termAndConditions',
    //     headerName: 'Terms and Conditions',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    {
        field: 'imprint',
        headerName: 'Imprint',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'explanation',
        headerName: 'Explanation',
        inputType: 'text',
        width: 150,
        editable: false
    },
];

export default columns;