import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Vendoapp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles(() => ({
  paper: {
    marginTop: "8em !important",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: "1em !important",
    backgroundColor: "#f50057 !important",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: "1em !important",
  },
  submit: {
    margin: '1em 0 1em 0 !important',
  },
  otp: { 
    marginLeft: '7%',   
    marginTop: "2em !important",
  }
}));

const EmailInput = (props) => {
  const [email , setEmail] = useState('')
  const classes = useStyles();

  let navigate = useNavigate();



  const handlePost = (body, endpoint) => {
    if(email === "" || email === null){
        alert("Please enter a email.")
        return
    }
  axios.post(`${URL}${endpoint}`, body)
    .then(res => {
        console.log('get data from email post --->', res);
      let data = res.data
      if (data.status) {
        console.log('get data from email post --->', data);
        navigate('/otp_verify')
        localStorage.setItem('email' , email)
        
      } else {
        alert("get false data from email post --->",data.msg)
      }
    })
    .catch(e => {
      console.log(e)
      alert(e.message)
    })
}

 // console.log("email --->", {email: email});

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" >
          Forgot Password
        </Typography>
        <Typography variant="subtitle1" gutterBottom mt={2}>
         Please enter your email id for otp verification.
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            //disabled = {true}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={email}
            // autoComplete="username"
            onChange={(e) => {
              // console.log(e.currentTarget.value);
              setEmail(e.currentTarget.value)
            }}
          // autoFocus
          />
    
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
                handlePost({
                    email: email
                  }, "/users/sendOtp")
            }}
          >
            Get otp
          </Button>
        </form>
      </div>
      <Box mt={18}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default EmailInput
