import { useState, useEffect } from "react";
// import _ from 'underscore';
import { TextField,TextareaAutosize, Switch, Button, FormControlLabel, Fab, Typography, Stack, Paper, Box,Select,MenuItem, Modal } from "@mui/material"
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from 'react-router-dom';
import structures from '../../../data_formats';
import notRequired from '../../../data_formats/notRequiredFields'

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../common/loder'
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../../store/MapToProps";
import axios from 'axios';

import $ from 'jquery'

import moment from 'moment'


let values = {}
let fields = []

let ignoredFields = {
  emailVerified: false,
  deactivate: false
}


const getFilteredField = (key) => {
  let array1 = notRequired[key].map(function (item) {
    return item;
  });

  let filtered = fields.filter(function (item) {
    return array1.indexOf(item) === -1;
  });

  // console.log("total : ", fields)
  // console.log("notRequired : ", notRequired[key])
  // console.log("filtered : ", filtered)

  return filtered
}

const checkElements = (target, array) => {
  const checker = target.every(v => array.includes(v));
  console.log("checker", checker)

  return checker
}

const handleSubmit = (props, category) => {

  values = {
    ...values,
    ...ignoredFields
  }

  console.log("values", values);
  let keys = Object.keys(values)
  if (document.getElementById("add_paypalMode").checked) {
    fields.push("paypalClientId")
    console.log(fields)
  } else {
    fields.pop("paypalClientId")
  }

  const filtered = getFilteredField(category)
  const checker =true //checkElements(filtered, keys)
  // console.log(filtered, checker)
  // if(document.getElementById("add_paypalMode").checked){}

  if (!checker) {
    alert(filtered.join("\n") + "\n - Fields are mandatory")
    return false
  }

  if(fields.includes("providerName")){
    if(values.providerName){
    if(values.providerName === "" || values.providerName === null ){
      alert("Please enter provider name.")
        return;
    }
  }else {
    alert("Please provide your name.")
    return false
  }
  }


  if(fields.includes("email")){

    if(values.email){
      if(values.email === " " || values.email === null){
        alert("Please provide your email id.")
      }
  
      const validateEmail = () => {
        var regexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return regexp.test(values.email.trim());
      };
    
      if (!validateEmail()) {
        alert("Please enter valid email id.")
        return;
      }
    }else {
      alert("Please provide your email.")
      return false
    }

  }


  if (fields.includes("password")) {
    if (values.password) {

      const validatePassword = () => {
        var regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regexp.test(values.password.trim());
      };
    
      if (!validatePassword()) {
        alert("Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        return;
      }

      if (values.verifyPassword) {
        if (!(values.password === values.verifyPassword)) {
          alert("Password didn't match")
          return 
        }
      }
      
      // else {
      //   alert("Please provide password again")
      //   return false
      // }
    } 
    // else {
    //   alert("Please provide password")
    //   return false
    // }
  }

  // if(!fields.includes('deliveryCost')){
  //   alert("Please provide delivery cost")
  //   return 
  // }
  // if(!fields.includes('minOrderCost')){
  //   alert("Please provide Minimum Order cost")
  //   return 
  // }
  if(values.deliveryCost==undefined || values.deliveryCost==null || values.deliveryCost==''){
    alert("Please provide Delivery cost")
     return 
  }

  if(values.minOrderCost==undefined || values.minOrderCost==null || values.minOrderCost==''){
    alert("Please provide Minimum Order cost")
     return 
  }

  if(values.pdfDocument){
    if(values.DocumentStartDay==undefined || values.DocumentStartDay==null || values.DocumentStartDay==''){
      alert("Please provide Document Start Date")
       return 
    }
    if(values.DocumentEndDay==undefined || values.DocumentEndDay==null || values.DocumentEndDay==''){
      alert("Please provide Document End Date")
       return 
    }

    if(values.DocumentStartDay>=values.DocumentEndDay){
      alert("Please provide Document Valid Date Range")
       return 
    }
  }
  
  if(keys.includes('company_presentation_feed_show_status') && values.company_presentation_feed_show_status ){
    if(values.company_presentation_subject_line==undefined || 
      values.company_presentation_input_text==undefined ||
      values.company_presentation_picture_1 ==undefined || 
      values.companyPresentationWelcomeStartDay==undefined || 
      values.companyPresentationWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Company Presentation")
  return;
  }else if(values.companyPresentationWelcomeStartDay>= values.companyPresentationWelcomeEndDay){
    alert("Please Provide valid Date range for Company Presentation")
  return;
  }}
  
  if(keys.includes('job_advertisement_feed_show_status') && values.job_advertisement_feed_show_status){
    if(values.job_advertisement_subject_line==undefined || 
      values.job_advertisement_input_text==undefined ||
      values.job_advertisement_picture_1 ==undefined || 
      values.jobAdvertisementWelcomeStartDay==undefined || 
      values.jobAdvertisementWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Job Advertisement")
  return;
  }else if(values.jobAdvertisementWelcomeStartDay>=values.jobAdvertisementWelcomeEndDay ){
    alert("Please Provide valid Date range for Job Advertisement")
  return;
  }}
  
  if(keys.includes('flyer_feed_show_status') && values.flyer_feed_show_status){

    if(values.flyer_subject_line==undefined || 
      values.flyer_input_text==undefined ||
      values.flyer_picture_1 ==undefined || 
      values.flyerWelcomeStartDay==undefined || 
      values.flyerWelcomeEndDay==undefined ) 
  {
  alert("Please Provide all details for Flyer")
  return;
  }else if(values.flyerWelcomeStartDay>=values.flyerWelcomeEndDay){
    alert("Please Provide valid Date range for Flyer")
  return;
  }}


  
  if(keys.includes('advertisement_feed_show_status') && values.advertisement_feed_show_status){
  
    if(values.advertisement_subject_line==undefined || 
      values.advertisement_input_text==undefined ||
      values.advertisement_picture_1 ==undefined || 
      values.advertisementWelcomeStartDay==undefined || 
      values.advertisementWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Advertisement")
  return;
  }else if( values.advertisementWelcomeStartDay>=values.advertisementWelcomeEndDay){
    alert("Please Provide valid Date range for Advertisement")
  return;
  }}
  
  if(keys.includes('menu_feed_show_status') && values.menu_feed_show_status){
  
    if(values.menu_subject_line==undefined || 
      values.menu_input_text==undefined ||
      values.menu_picture_1 ==undefined || 
      values.menuWelcomeStartDay==undefined || 
      values.menuWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Menu")
  return;
  }else if(values.menuWelcomeEndDay <=values.menuWelcomeStartDay){
    alert("Please Provide valid Date range for Menu")
  return;
  }}
  
  if(keys.includes('info_feed_show_status' )&& values.info_feed_show_status){
  
    if(values.info_subject_line==undefined || 
      values.info_input_text==undefined ||
      values.info_picture_1 ==undefined || 
      values.infoWelcomeStartDay==undefined || 
      values.infoWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Info")
  return;
  }else if(values.infoWelcomeEndDay <=values.infoWelcomeStartDay){
    alert("Please Provide valid Date range for Info")
  return;
  }}
  
  if(keys.includes('event_feed_show_status')  && values.event_feed_show_status){
  
    if (values.event_subject_line==undefined || 
      values.event_input_text==undefined ||
      values.event_picture_1 ==undefined || 
      values.eventWelcomeStartDay==undefined || 
      values.eventWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Event")
  return;
  }else if(values.eventWelcomeEndDay <=values.eventWelcomeStartDay){
    alert("Please Provide valid Date range for Event")
  return;
  }}
  
  if(keys.includes('advertising_video_feed_show_status') && values.advertising_video_feed_show_status){
  
    if(values.advertising_video_subject_line==undefined || 
      values.advertising_video_input_text==undefined ||
      values.advertising_video_picture_1 ==undefined || 
      values.advertisingVideoWelcomeStartDay==undefined || 
      values.advertisingVideoWelcomeEndDay==undefined )
  {
  alert("Please Provide all details for Advertising Video")
  return;
  }else if(values.advertisingVideoWelcomeEndDay <=values.advertisingVideoWelcomeStartDay){
    alert("Please Provide valid Date range for Advertising Video")
  return;
  }}


  var formData = new FormData();

  keys.forEach(e => {
    console.log("====e==>",e,values[e])
    if(e=='PRFeedData'){
      if(values[e].length==1){
        formData.append('PRFeedData', [JSON.stringify(values[e])])
      }else{
        values[e].map(item=>
          formData.append('PRFeedData', JSON.stringify(item)))
      }
      
    }else{
      formData.append(e, values[e])
    }
  });

  for (var [key, value] of formData.entries()) {
    console.log('formData.entries===>', key, value)
  }
  
  switch (category) {
    case "Admin":
      props.addUser(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Customer":
      props.addCustomer(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Provider":
      props.enableLoder()
      props.addProvider(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Voucher":
      props.addVoucher(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Product":
      props.addProduct(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    default:
      break;
  }
}

const checkField = (field = "", state = {}) => {
  if (["paypalClientId","paypalClientSecret"].includes(field)) {
    // console.log(field)
    if (state.paypalMode) {
      return ""
    } else {
      return "none"
    }
  } else {
    return ""
  }
}

$(document).on('change', "#add_paypalMode", (e) => {
  // console.log(e.target.checked)
  let wd = $("#add_providerName").width()
  if (e.target.checked) {
    console.log('document clicked in if');
    $(".add_paypalClientId").css("display", "block");
    $("#add_paypalClientId").width(wd);
    $(".add_paypalClientSecret").css("display", "block");
    $("#add_paypalClientSecret").width(wd);
    notRequired.Provider.pop("paypalClientId")
  } else {
     console.log('document clicked in else');
    $(".add_paypalClientId").css("display", "none");
    notRequired.Provider.push("paypalClientId")
    $(".add_paypalClientSecret").css("display", "none");
    notRequired.Provider.push("paypalClientSecret")
  }
})
const GenerateFields = ({ location, props }) => {

  let handleReset;
  let currentDate = moment(new Date()).format('YYYY-MM-DD');

  const GetField = ({ structure, id }) => {
 
    const [filename, setFilename] = useState({});
    const [item, setItem] = useState({})
    const [multipleImage, setMultipleImage] = useState([])
    const [multiplePRText, setMultiplePRText] = useState([])
 
    handleReset = () => {
      console.log("reseting state")
      setFilename({})
      setItem({})
      values = {}
    }
   
    const [data4, setData4] = useState([]);
    const [data9, setData9]= useState([]);
    const [data, setData] = useState([])

    useEffect(()=>{
      
      if(data.length<=0 && structure.inputType=='select'){
      axios
        .get(process.env.REACT_APP_API_URL + "/getcategory?type="+structure.data)
        .then((res) => {
          setData(res.data.result)
        })
      }

      if(data4.length<=0 && structure.inputType=='select2'){

        axios
          .get(process.env.REACT_APP_API_URL + "/getcategory?type="+structure.data4)
          .then((res) => {
            setData4(res.data.result)
          })
      }

      if(data9.length<=0 && structure.inputType=='select9'){
          axios
            .get(process.env.REACT_APP_API_URL + "/getcategory?type="+structure.data9)
            .then((res) => {
              setData9(res.data.result)
            })
      }

     }, [])

    switch (structure.inputType) {
      case "text":
        return (
          <TextField
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
            type={structure.inputType}
            label={structure.headerName}
            autoComplete='false'
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : checkField(structure.field, item)
            }}
            value={item[structure.field] || ""}
            onChange={(e) => {
              let key = structure.field
              setItem({ ...item, [key]: e.currentTarget.value })
              values = { ...values, [key]: e.currentTarget.value }
            }}
            disabled={structure.isDisabled ? true : false}
          />
        )
        case "multipleText":
        return (
          < >
          {multiplePRText.map((item,index)=>{
            return(
              <Stack direction="row" spacing={2}  key={index}>
              <TextField
             
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`}
              type={structure.inputType}
               label={structure.headerName}
               autoComplete='false'
               variant="outlined"
               sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : checkField(structure.field, item)
            }}
            value={multiplePRText[index]}
            onChange={(e) => {
              
              let key = structure.field
              const temp=[...multiplePRText]
              temp[index]=e.currentTarget.value
              setMultiplePRText(temp)
               setItem({ ...item, [key]: multiplePRText })
              values = { ...values, [key]:multiplePRText }
              console.log("===multiplePRText===>",multiplePRText)
            }}
              disabled={structure.isDisabled ? true : false}
          />
           <Paper
            elevation={3} 
            sx={{ position: "relative" }}>
                <CancelIcon
                  onClick={(e) => {
                    let key = structure.field
                     //alert("clicked",index)
                     console.log("====clicked====>",index)
                     var tempArr=multiplePRText.filter((item,i)=>i!=index)

                     setMultiplePRText(tempArr)
                     setItem({ ...item, [key]: tempArr })
                     values = { ...values, [key]:tempArr }

                  }}
                  sx={{
                    right: "-20px",
                   // top: "-8px",
                    position: "absolute"
                  }} />
                
            </Paper>
          </Stack>
            )
          })}
          {multiplePRText.length<10 &&
         
                <Fab
                style={{marginBottom:'20px'}}
                  color="secondary"
                  component="span"
                  aria-label="add"
                  variant="extended"
                  onClick={()=>{
                    if(multiplePRText.length==10){
                      alert("Maximum of 10 Text only")
                    }else{
                      setMultiplePRText([...multiplePRText,""])
                    }
                    
                  }}
                >
                  <AddIcon />{structure.headerName}
                </Fab>}
                </>
        )
        case "multiText":
          return (
            <>
             
              <TextareaAutosize
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={structure.inputType}
                label={structure.headerName}
                 placeholder={structure.headerName}
                autoComplete='false'
                maxRows={10}
                minRows={8}
                variant="outlined"
                aria-label="maximum height"
                style={{
                  marginBottom: "2em",
                  width: "27em",
                fontSize: "18px",padding:"0.5em",
                  display: structure.hideInput ? "none" : checkField(structure.field, item)
                }}
                value={item[structure.field] || ""}
                onChange={(e) => {
                  let key = structure.field
                  setItem({ ...item, [key]: e.currentTarget.value })
                  values = { ...values, [key]: e.currentTarget.value }
                }}
                disabled={structure.isDisabled ? true : false}
              />
            </>
          )
        case "select":
          return (
           
          <FormControl style={{width:'45.2%',marginBottom:'3%'}}>
              
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
           value={item[structure.field] || ""}
            onChange={(e) => {
              
              let key = structure.field
              setItem({ ...item, [key]: e.target.value })
              values = { ...values, [key]: e.target.value }
            }}
           
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`} >
               {data.map((ans,i)=>
               
            <MenuItem key={i+1} value={ans.Name}>{ans.Name}</MenuItem> 
            )}
            </Select>
          </FormControl>
          )
          case "select9":
          return (
           
          <FormControl style={{width:'45.2%',marginBottom:'3%'}}>
              
          <InputLabel id="demo-simple-select-label">Branch</InputLabel>
            <Select
           value={item[structure.field] || ""}
            onChange={(e) => {
              
              let key = structure.field
              setItem({ ...item, [key]: e.target.value })
              values = { ...values, [key]: e.target.value }
            }}
           
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`} >
               {data9.map((ans,i)=>
               
            <MenuItem key={i+1} value={ans.Name}>{ans.Name}</MenuItem> 
            )}
            </Select>
          </FormControl>
          )
          case "select2":
            return (
             
              <FormControl style={{width:'45.2%',marginBottom:'3%'}}>
                
               <InputLabel id="demo-simple-select-label">Community</InputLabel>
              <Select
             value={item[structure.field] || ""}
              onChange={(e) => {
                
                let key = structure.field
                setItem({ ...item, [key]: e.target.value })
                values = { ...values, [key]: e.target.value }
              }}
             
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`} >
                 {data4.map((ans,i)=>
                 
  <MenuItem key={i+1} value={ans.Name}>{ans.Name}</MenuItem>
                )}
    </Select>
    </FormControl>
            )
      case "date":
        return (
          <TextField
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
            type={structure.inputType}
            label={structure.headerName}
            autoComplete='false'
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : ""
            }}
            value={item[structure.field] || currentDate}
            inputProps={{ min:currentDate }} 
            onSelect={(e)=>{
            console.log("onSelect==>",e.target.value)
            let key = structure.field
            setItem({ ...item, [key]: e.target.value })
            values = { ...values, [key]: e.target.value }
          }}
            onChange={(e) => {
              let key = structure.field
              setItem({ ...item, [key]: e.currentTarget.value })
              values = { ...values, [key]: e.currentTarget.value }
            }}
            disabled={structure.isDisabled ? true : false}
          />
        )
      case "file":
        return (
          <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
            <Stack direction="row" spacing={2}>
              <label htmlFor={`${id}_${structure.field}`}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name={`${id}_${structure.field}`}
                  className={`${id}_${structure.field}`}
                  id={`${id}_${structure.field}`}
                  autoComplete='false'
                  onChange={(e) => {
                    let size = e.target.files[0].size
                   
                    let file = document.getElementById(e.currentTarget.id)
                    
                    let nameArr = ((file.files[0] || {}).name || "").split(".")
                   
                    let ext = nameArr[nameArr.length - 1]
                    


                    if (!(structure.fileType || []).includes(ext)) {
                      alert("Please Upload these files -> " + structure.fileType.join(", "))
                      return false
                    }else{
                      if( size > 36700165 ){
                       alert("Please Upload files upto 35 mb.")
                       return false
                    }
                    }

                

                    let key = structure.field
                    values = { ...values, [key]: file.files[0] }
                    if(ext=='pdf'){
                    var tmppath =require('../../../Assets/Images/pdf.png')
                  }else{
                    var tmppath = URL.createObjectURL(e.target.files[0]);
                  }
                   // var tmppath = URL.createObjectURL(e.target.files[0]);
                    // console.log(tmppath)
                    setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                  }}
                />

                <Fab
                  color="secondary"
                  component="span"
                  aria-label="add"
                  variant="extended"
                >
                  <AddIcon />{structure.headerName}
                </Fab>
              </label>

              {filename[structure.field + "_preview"] ? (<Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    height: 120,
                    width: 120
                  },
                }}
              >
                <Paper elevation={3}>
                  <img style={{ borderRadius: "2px" }} height="120" width="120" alt="none" 
                  src={filename[structure.field + "_preview"]}
                  />
                </Paper>
              </Box>) : (<></>)}
              <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography>
            </Stack>
          </div>
        )
        case "videoFile":
          return (
            <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
              <Stack direction="row" spacing={2}>
                <label htmlFor={`${id}_${structure.field}`}>
                  <input
                    style={{ display: "none" }}
                    name={`${id}_${structure.field}`}
                    type="file"
                    className={`${id}_${structure.field}`}
                    id={`${id}_${structure.field}`}
                    onChange={(e) => {
                      let size = e.target.files[0].size
                      let file = document.getElementById(e.currentTarget.id)
                      let nameArr = ((file.files[0] || {}).name || "").split(".")
                      let ext = nameArr[nameArr.length - 1]
    
                      if (!(structure.fileType || []).includes(ext)) {
                        alert("Please Upload these files -> " + structure.fileType.join(", "))
                        return false
                      }else{
                          if( size > 36700165 ){
                           alert("Please Upload files upto 35 mb.")
                           return false
                        }
                        }
    
    
                      let key = structure.field
                      values = { ...values, [key]: file.files[0] }
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      // values.append(key, file.files[0])
                      setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                    }}
                  />
    
                  <Fab
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    <AddIcon /> {structure.headerName}
                  </Fab>
                </label>
                {(item[structure.field] || filename[structure.field + "_preview"]) ? (
                  <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                      height: 100,
                      width: 120
                    },
                  }}
                >
                  <Paper elevation={3} sx={{ position: "relative" }}>
                    <CancelIcon
                      onClick={(e) => {
                        // alert("clicked")
                        setFilename({
                          ...filename,
                          [structure.field + "_preview"]: null
                        })
                        setItem({
                          ...item, [structure.field]: null
                        })
                        values[structure.field] = {}
                      }}
                      sx={{
                        right: "-20px",
                        top: "-18px",
                        position: "absolute"
                      }} />
                    {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? 
                    <img style={{ borderRadius: "2px" }} height="100" width="120" 
                src={require('../../../Assets/Images/video.png')} 
                alt={require('../../../Assets/Images/video.png')}/> : <></>}
                  </Paper>
                </Box>
                ) : (<></>)}
                {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography> : <></>}
              </Stack>
            </div>
           )
    
        case "pdfFile":
          return (
            <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
              <Stack direction="row" spacing={2}>
                <label htmlFor={`${id}_${structure.field}`}>
                  <input
                    style={{ display: "none" }}
                    name={`${id}_${structure.field}`}
                    type="file"
                    className={`${id}_${structure.field}`}
                    id={`${id}_${structure.field}`}
                    onChange={(e) => {
                      let size = e.target.files[0].size
                      let file = document.getElementById(e.currentTarget.id)
                      let nameArr = ((file.files[0] || {}).name || "").split(".")
                      let ext = nameArr[nameArr.length - 1]
    
                      if (!(structure.fileType || []).includes(ext)) {
                        alert("Please Upload these files -> " + structure.fileType.join(", "))
                        return false
                      }else if(ext !== 'pdf'){
                          
                           alert("Please Upload pdf only.")
                           return false
                        
                       
                        }
    
    
                      let key = structure.field
                      values = { ...values, [key]: file.files[0] }
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      // values.append(key, file.files[0])
                      setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                    }}
                  />
    
                  <Fab
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                   
                  >
                    <AddIcon /> {structure.headerName}
                  </Fab>
                </label>
                {(item[structure.field] || filename[structure.field + "_preview"]) ? (
                  <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                      height: 120,
                      width: 120
                    },
                  }}
                >
                  <Paper elevation={3} sx={{ position: "relative" }}>
                    <CancelIcon
                      onClick={(e) => {
                        // alert("clicked")
                        setFilename({
                          ...filename,
                          [structure.field + "_preview"]: null
                        })
                        setItem({
                          ...item, [structure.field]: null
                        })
                        values[structure.field] = {}
                      }}
                      sx={{
                        right: "-20px",
                        top: "-18px",
                        position: "absolute"
                      }} />
                    {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? <img style={{ borderRadius: "2px" }} height="120" width="120" src={require('../../../Assets/Images/pdf.png')} /> : <></>}
                  </Paper>
                </Box>
                ) : (<></>)}
                {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography> : <></>}
              </Stack>
            </div>
           )
        case "multiFile":
          return (
            <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
              <Stack direction="row" spacing={2}>
                <label htmlFor={`${id}_${structure.field}`}>
                  <input
                    style={{ display: "none" }}
                    name={`${id}_${structure.field}`}
                    type="file"
                   // multiple={true}
                    className={`${id}_${structure.field}`}
                    id={`${id}_${structure.field}`}
                    onChange={(e) => {
                      //console.log("Hello")
                      console.log("=======e.target========>",e.target)
                      let size = e.target.files[0].size
                      let file = document.getElementById(e.currentTarget.id)
                      let nameArr = ((file.files[0] || {}).name || "").split(".")
                      let ext = nameArr[nameArr.length - 1]
    
                      if (!(structure.fileType || []).includes(ext)) {
                        alert("Please Upload these files -> " + structure.fileType.join(", "))
                        return false
                      }else if(ext === 'jpg' || ext === "jpeg" || ext === "png"){
                          if( size > 10485760 ){
                           alert("Please Upload files under 10 mb.")
                           return false
                        }
                        }else{
                          if( size > 10485760 ){
                           alert("Please Upload files under 10 mb.")
                           return false
                        }
                        }
    
    
                     // let key = structure.field
                      //values = { ...values, [key]: file.files[0] }
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      // let temp=filename[key]
                      // temp.push(file.files[0].name)
                      // let temp1=filename[key+ "_preview"]
                      // temp1.push(tmppath)
                      //setFilename({ ...filename, [key]:temp, [key + "_preview"]: temp1})
                      let tempArr=multipleImage
                       var temp={
                        name:file.files[0].name,
                        file:tmppath
                       }
                      // temp[key]=file.files[0].name,
                      // temp[key+ "_preview"]=tmppath
    
                      tempArr.push(temp)
                      setMultipleImage([...tempArr])
                   
    
    
                      console.log("=========item==========>",multipleImage)
                     console.log("===========filename=========>",filename)
                    }}
                  />
    
                  <Fab
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    <AddIcon /> {structure.headerName}
                  </Fab>
                </label>
               
                  
                  {multipleImage.map((item1,index)=>{
                    return(
                      <Box
                      key={index}
                    sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                      height: 120,
                      width: 120
                    },
                  }}
                >
                  <Paper elevation={3} sx={{ position: "relative" }}>
                    <CancelIcon
                      onClick={(e) => {
                        console.log("item1.file",item1.file)
                       var tempArr= multipleImage.filter(item2=> {return(item2.file!=item1.file)})
                       setMultipleImage(tempArr)
                        //values[structure.field] = {}
                      }}
                      sx={{
                        right: "-20px",
                        top: "-18px",
                        position: "absolute"
                      }} />
                    {<img style={{ borderRadius: "2px" }} height="120" width="120" src={item1.file}/>}
                  </Paper>
                </Box>
                    )
                  })}
                  
                
              </Stack>
            </div>
          )
      case "switch":
        return (
          <FormControlLabel
            control={<Switch
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              checked={(item[structure.field] ? true : false)}
              autoComplete='false'
              onChange={(e) => {
                let key = structure.field
                setItem({ ...item, [key]: e.currentTarget.checked })
                values = { ...values, [key]: e.currentTarget.checked }
              }}
            />}
            label={structure.headerName}
            disabled={structure.isDisabled ? true : false}
            sx={{
              marginBottom: "2em",
              width: "30em"
            }}
          />
        )

      case "password":
        return (
          <FormControl
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : ""
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">{structure.headerName}</InputLabel>
            <OutlinedInput
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`}
              label={structure.headerName}
              value={item[structure.field] || ""}
              onChange={(e) => {
                let key = structure.field
                setItem({ ...item, [key]: e.currentTarget.value })
                values = { ...values, [key]: e.currentTarget.value }
              }}
              type={item["show" + structure.field] ? 'text' : 'password'}
              inputProps={{
                autoComplete: `new-${structure.field}`,
                form: {
                  autoComplete: 'off',
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      e.preventDefault()
                      let key = structure.field
                      setItem({ ...item, ["show" + key]: !item["show" + key] })
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                    }}
                    edge="end"
                  >
                    {item.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        )
        case "heading":
          return(
            <h3>{structure.title}</h3>
          )
          
      default:
        return (
          <></>
        )
    }
  }

  return (
    <>
      {structures[location.state.fieldKey].map((item, index) => (
        <div key={index}>
          <GetField structure={item} id={"add"} />
        </div>
      ))}

    <Loader/>

      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
        }}
        onClick={(e) => {
          handleSubmit(props, location.state.pageTitle)
        }}
      >
        ADD
      </Button>
      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
          marginLeft: "3em"
        }}
        onClick={(e) => {
          handleReset()
        }}
      >
        RESET
      </Button>
    </>
  )
}

const AddData = (props) => {
  const location = useLocation();

  // useEffect(() => {
    values = {}
    fields = []
    structures[location.state.fieldKey].forEach((i) => {
      if (!i.hideInput)
        fields.push(i.field)
    })
  // }, [])

  return (
    <>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        {`Add ${location.state.pageTitle}`}
      </Typography>

      <GenerateFields location={location} props={props} />
    </>
  )
}

export default connect(mapStateToProps, mapDispachToProps)(AddData);