import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { categoryStructure } from '../../data_formats';

const CategoryTable = (props) => {
  React.useEffect(() => {
    props.getAllcategory();
    console.log('4');
   console.log(props);
  }, [])
  return (
    <>
   
    <Table
        title={"Category"}
        pageTitle={"Category"}
        columns={categoryStructure}
        rows={props.categorys.categorys}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"categoryStructure"}
        dataKey={"categorys"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(CategoryTable);