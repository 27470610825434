import {
  GET_ALL_TRANSACTIONS,
  SEARCH_TRANSACTIONS
} from '../actions/types';

const initialState = {
  transactions: [],
  copyTransactions: [],
  data: []
};

const customerReducer = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case GET_ALL_TRANSACTIONS:
      return { ...state, transactions: payload, copyTransactions: payload };

    case SEARCH_TRANSACTIONS:
      if(payload.length <= 0){
        return { ...state, transactions: state.copyTransactions }
      }

      const regex = new RegExp(payload, 'i')
      let filteredTransaction = []
      state.copyTransactions.forEach(e => {
        if (regex.test(e.id)) {
          filteredTransaction.push(e)
        }
      })

      return { ...state, transactions: filteredTransaction }

    default:
      return state;
  }
};

export default customerReducer;
