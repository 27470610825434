import { Checkbox } from '@mui/material';

const columns = (handleDeactivateProduct) => [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 240,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'pid',
        headerName: 'Provider ID',
        inputType: 'text',
        width: 240,
        isDisabled: false,
        hideInput: false,
        valueGetter: (params) => (params.row._provider || {}).id || ""
    },
    { 
        field: 'providerName',
        headerName: 'Provider Name',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true,
        valueGetter: (params) => (params.row._provider || {}).providerName || ""
    },
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'maxQuantity',
        headerName: 'Max Quantity',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'inStock',
        headerName: 'In Stock',
        inputType: 'text1',
        width: 150,
        isDisabled: true,
        hideInput: true,
       // valueGetter: (params) => (params.row._provider || {}).inStock || ""
    },
    {
        field: 'category',
        headerName: 'Category',
        inputType: 'select',
        width: 150,
        editable: false,
        data:'Product',
    },
    {
        field: 'size',
        headerName: 'Size',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'price',
        headerName: 'Price',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'ingredients',
        headerName: 'Ingredients',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'shortDescription',
        headerName: 'Short Description',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'longDescription',
        headerName: 'Long Description',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'productImage',
        headerName: 'Product Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'status',
        headerName: 'Status',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.status ? "Yes" : "No"
    },
 
    // {
    //     field: 'deactivate',
    //     headerName: 'Deactivated',
    //     inputType: 'switch',
    //     width: 150,
    //     editable: false,
    //     valueGetter: (params) => params.row.deactivate ? "Yes" : "No"
    // },
    {
        field: 'deactivateProduct',
        headerName: 'Deactivate',
        width: 150,
        inputType: 'switch',
        renderCell: (params) => (
          <Checkbox
            checked={params.row.deactivate}
            color="default"
            onChange={(e) => {
                handleDeactivateProduct({
                    id: params.row.id,
                    deactivate: e.target.checked
                });
            }}
          />
        ),
      }
];


export default columns;