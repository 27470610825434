import React from 'react';
import $ from 'jquery';
import { Box, CssBaseline } from '@mui/material';
import { makeStyles } from "@mui/styles";

import './App.css';

import Login from './components/login/login';

import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./components/navigation";
import LeftNav from "./components/navigation/LeftNav";
import AppBar from "./components/navigation/AppBar";

import CustomerTable from "./components/TableGrid/CustomerTable"
import AdminUserTable from "./components/TableGrid/AdminUserTable"
import ProviderTable from "./components/TableGrid/ProviderTable"
import VoucherTable from "./components/TableGrid/VoucherTable"
import ProductTable from "./components/TableGrid/ProductTable"
import TransactionTable from "./components/TableGrid/TransactionTable"
import TransactionVouchersTable from './components/TableGrid/TransactionVouchersTable';
import AddContents from "./components/inputs/add"
import StaticPageAdd from "./components/inputs/StaticPage/add"
import AddProvider from "./components/inputs/Providers/add"
import UpdateContents from "./components/inputs/update"
import UpdateProvider from "./components/inputs/Providers/update"
import CategoryTable from "./components/TableGrid/CategoryTable"
import NotificationTable from "./components/TableGrid/NotificationTable"
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "./store/MapToProps";
import EmailInput from './components/ForgotPassword/emailInput';
import ConfirmPassword from './components/ForgotPassword/confirmPassword';
import OtpVarify from './components/ForgotPassword/verifyOtp';
import HowItWorksManager from './components/inputs/HowItWorks';

window.onload = function () {
  const expr = localStorage.getItem("expr")
  // if (!expr) {
  //   console.log("Logging out 1");
  //   localStorage.removeItem("uid")
  //   localStorage.removeItem("priority")
  //   localStorage.removeItem("expr")
  //   window.location = "/login"
  // }

  if (expr) {
    let time = (new Date()).getTime()
    if ((expr - time) <= 0) {
      alert("Your session expired")
      console.log("Logging out 2");
      localStorage.removeItem("uid")
      localStorage.removeItem("priority")
      localStorage.removeItem("expr")
      window.location = "/login"
    }
  }
}

setInterval(() => {
  const expr = localStorage.getItem("expr")
  if (expr) {
    let time = (new Date()).getTime()
    if ((expr - time) > 0){
      console.log("Refresh")
      localStorage.setItem("expr", (new Date()).getTime() + (5 * 60 * 1000))
    }
  }
}, 1 * 60 * 1000);

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh"
  }
}));

const validateLogin = () => {
  return localStorage.getItem("uid") ? true : false;
};

const BoxComponent = ({ children }) => {

  return (
    <Box component="main" id={"main-area"} sx={{ flexGrow: 1, p: 3 }}>
      {children}
    </Box>
  )
}

if (window.location.pathname === "/") {
  let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
  if ([1].includes(priority))
    window.location = "/dashboard"
  else if ([2].includes(priority))
    window.location = "/products"
  else
    window.location = "/login"
}

const App = (props) => {
  const classes = useStyles();
  let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1

  React.useEffect(() => {
    $('#main-area').css({ "margin-top": $('.app-bar').height() + "px" })
  }, [])

  return (
    <React.Fragment>
      <BrowserRouter>
        <Box sx={{ display: 'flex' }} classes={classes} >
          <CssBaseline />
          {validateLogin() ? <AppBar /> : <></>}
          {validateLogin() ? <LeftNav /> : <></>}
          <Switch>
            {[1].includes(priority) ? <><Route exact path="/dashboard" element={
              <PrivateRoute>
                <BoxComponent>
                  <AdminUserTable />
                </BoxComponent>
              </PrivateRoute>
            } />
              <Route exact path="/users" element={
                <PrivateRoute>
                  <BoxComponent>
                    <AdminUserTable />
                  </BoxComponent>
                </PrivateRoute>
              } /> </> : <></>}
            <Route exact path="/customers" element={
              <PrivateRoute>
                <BoxComponent>
                  <CustomerTable />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/providers" element={
              <PrivateRoute>
                <BoxComponent>
                  <ProviderTable />
                </BoxComponent>
              </PrivateRoute>
            } />
             <Route exact path="/categorys" element={
              <PrivateRoute>
                <BoxComponent>
                  <CategoryTable />
                </BoxComponent>
              </PrivateRoute>
            } />

            <Route exact path="/products" element={
              <PrivateRoute>
                <BoxComponent>
                  <ProductTable />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/vouchers" element={
              <PrivateRoute>
                <BoxComponent>
                  <VoucherTable />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/transactions" element={
              <PrivateRoute>
                <BoxComponent>
                  <TransactionTable />
                </BoxComponent>
              </PrivateRoute>
            } />
             <Route exact path="/transactionsVoucher" element={
              <PrivateRoute>
                <BoxComponent>
                  <TransactionVouchersTable />
                </BoxComponent>
              </PrivateRoute>
            } />
              <Route exact path="/notifications" element={
              <PrivateRoute>
                <BoxComponent>
                  <NotificationTable />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/add_provider" element={
              <PrivateRoute>
                <BoxComponent>
                  <AddProvider fieldKey={"adminStructure"} pageTitle={"Admin"} id={"dsadadi76udsa"} />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/adddata" element={
              <PrivateRoute>
                <BoxComponent>
                  <AddContents fieldKey={"adminStructure"} pageTitle={"Admin"} id={"dsadadi76udsa"} />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/updatedata" element={
              <PrivateRoute>
                <BoxComponent>
                  <UpdateContents />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/howitworks" element={
              <PrivateRoute>
                <BoxComponent>
                  <HowItWorksManager />
                </BoxComponent>
              </PrivateRoute>
            } />

            <Route exact path="/static" element={
              <PrivateRoute>
                <BoxComponent>
                  <StaticPageAdd fieldKey={"staticStructure"} pageTitle={"Static data"} id={"i76udsa"} />
                </BoxComponent>
              </PrivateRoute>
            } />

            <Route exact path="/update_provider" element={
              <PrivateRoute>
                <BoxComponent>
                  <UpdateProvider />
                </BoxComponent>
              </PrivateRoute>
            } />
            <Route exact path="/login" element={
              <Login xyz={"test"} />
            } />
            <Route exact path="/email_input" element={
             <EmailInput />
            } />
            <Route exact path="/confirm_password" element={
             <ConfirmPassword />
            } />
            <Route exact path="/otp_verify" element={
             <OtpVarify />
            } />
            <Route path="*" element={<div>404</div>} />
          </Switch>
        </Box>
      </BrowserRouter>
    </React.Fragment>
  );


};

export default connect(mapStateToProps, mapDispachToProps)(App);