import {
  GET_ALL_PROVIDERS,
  GET_ONE_PROVIDER,
  SEARCH_PROVIDERS,ADDPRFIELD,EDITPRFIELD,DELETEPRFIELD
} from '../actions/types';

const initialState = {
  providers: [],
  copyProviders: [],
  data: []
};

const customerReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case GET_ALL_PROVIDERS:
      return { ...state, providers: payload, copyProviders: payload };

    case GET_ONE_PROVIDER:
      return { ...state, data: payload };

    case ADDPRFIELD:
      return { ...state, data: payload };

    case SEARCH_PROVIDERS:
      if(payload.length <= 0){
        return { ...state, providers: state.copyProviders }
      }

      const regex = new RegExp(payload, 'i')
      let filteredUsers = []
      state.copyProviders.forEach(e => {
        if (regex.test(e.providerName)) {
          filteredUsers.push(e)
        }
      })

      return { ...state, providers: filteredUsers }

    

    default:
      return state;
  }
};

export default customerReducer;
