import React, { useState, useEffect } from "react";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

const HowItWorksManager = () => {
  const [formData, setFormData] = useState({
    dealsAndBonusCards: "",
    feedAndStories: "",
    shopping: "",
    delivery: "",
    provider: "",
    flyer: "",
  });
  const [originalData, setOriginalData] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const URL = process.env.REACT_APP_API_URL;

  // Fetch data from the server
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${URL}/hiw`);
      if (response.data && response.data.length > 0) {
        const latestEntry = response.data[0];
        setFormData(latestEntry);
        setOriginalData(latestEntry);
      } else {
        showToast("No data available to display", "info");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showToast("Error fetching data. Check console for details.", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [URL]);

  const showToast = (message, severity) => {
    setToast({ open: true, message, severity });
  };

  const closeToast = () => {
    setToast({ open: false, message: "", severity: "" });
  };

  // Detect changes between original and current data
  useEffect(() => {
    const hasChanges = Object.keys(originalData).some(
      (key) => formData[key] !== originalData[key]
    );
    setIsUpdated(hasChanges);
  }, [formData, originalData]);

  // Handle SunEditor content changes
  const handleEditorChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData._id) {
      showToast("No valid ID found for update", "error");
      return;
    }
    try {
      await axios.put(`${URL}/hiw/${formData._id}`, formData);
      showToast("Data updated successfully!", "success");
      setIsUpdated(false);
      setOriginalData(formData); // Sync original data with updated data
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error);
      showToast("Error updating data. Check console for details.", "error");
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        How It Works
      </Typography>

      {/* Snackbar Notification */}
      <Snackbar
        open={toast.open}
        autoHideDuration={3000}
        onClose={closeToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={closeToast}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>

      {/* Loading Spinner */}
      {loading ? (
        <Typography align="center">
          <CircularProgress />
          <br />
          Loading data...
        </Typography>
      ) : (
        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {[
                  "dealsAndBonusCards",
                  "feedAndStories",
                  "shopping",
                  "delivery",
                  "provider",
                  "flyer",
                ].map((field) => (
                  <Grid item xs={12} key={field}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {field
                        .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
                        .replace(/^./, (str) => str.toUpperCase())}
                    </Typography>
                    <SunEditor
                      setContents={formData[field] || ""}
                      onChange={(value) => handleEditorChange(field, value)}
                      setOptions={{
                        buttonList: [
                          ["bold", "italic", "underline", "strike"],
                          ["align", "fontSize", "fontColor", "hiliteColor"],
                          ["list", "link", "image", "video"],
                          ["undo", "redo"],
                        ],
                        height: 200,
                      }}
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isUpdated} // Disable button if no changes
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default HowItWorksManager;
