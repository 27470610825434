import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
// import "./styles.css";

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">
          Vendoapp
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles(() => ({
    paper: {
      marginTop: "8em !important",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: "1em !important",
      backgroundColor: "#f50057 !important",
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: "1em !important",
    },
    submit: {
      margin: '1em 0 1em 0 !important',
    },
    otp: { 
      marginLeft: '7%',   
      marginTop: "2em !important",
      marginBottom: '5%'
    }
  }));

 

export default function OtpVarify() {
const [otp , setOtp] = useState('')
  const classes = useStyles();
  let navigate = useNavigate();
  console.log('otp --->', otp);

  const sendOtp = () =>{
    const count = otp.toString().length
    console.log('count ===>', count);
    if(count != 4){
        alert("Please enter 4 number otp code")
    }else{navigate('/confirm_password',{state:{otpNumber: otp}} )}

  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" marginBottom={3}>
        Verification Code
        </Typography>
        <Grid item xs={12} textAlign="center">
            <Paper elevation={0}>
              <Typography variant="h8">
                Please enter the verification code sent to your mobile
              </Typography>
            </Paper>
          </Grid>
        <form className={classes.form} noValidate>
          <div className={classes.otp}>
          <OtpInput
          value={otp}
          onChange={text => setOtp(text)}
              separator={
                <span>
                  <strong> - </strong>
                </span>
              }
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)"
              }}
            />
          </div>
    
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
                sendOtp()
            }}
          >
            Verify otp
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={16}>
        <Copyright />
      </Box>
    </Container>
  );
}