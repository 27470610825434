import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import AccountMenu from './Menu'

const Header = () => {
  return (
    <>
      <AppBar className={"app-bar"} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={0}
            sx={{
              width: "100%"
            }}
          >
            <Typography variant="h6" noWrap component="div" sx={{ m: "auto 0" }} >
              VendoApp
            </Typography>
            <AccountMenu />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header;
