import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//.env file content -->
// REACT_APP_API_URL_PRE="https://www.mein-vendoapp.de:3001"
// REACT_APP_API_URL="https://www.mein-vendoapp.de:3001"
// REACT_APP_WEB_URL="https://www.mein-vendoapp.de:3001"

// Credentials ->

// email - admin@vendev.xyz
// password - Admin@123

// Sftp details. -->

// host - 161.97.144.52
// Username - root
// Password - start2022

//url:-https://mein-vendoapp.de/

//backend

//backend -  /root/newvendoapp

//================================================================================================================
// Vendo
// ================================================================================================================
// Sftp details.

// host - 161.97.144.52
// Username - root
// Password - start2022
// cmd - root@161.97.144.52

// DB - admin
// Username - VendoApp22
// Password- Pa$$wo22rd
// ssh robo3t