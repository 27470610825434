import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from '../../store/MapToProps'; 

const Loder = (props) => {
  return (
    <>
    
    <Backdrop
        sx={{ color: '#0096FF', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.loder.loderState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      </>
  )
}

export default connect(mapStateToProps, mapDispachToProps)(Loder)