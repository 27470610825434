const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 250,
        isDisabled: true,
        hideInput: true
    },
    {
        field: 'name',
        headerName: 'Full name',
        description: 'Full Name',
        inputType: 'text',
        sortable: true,
        width: 160
    },
    {
        field: 'email',
        headerName: 'Email*',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'password',
        headerName: 'Password*',
        inputType: 'password',
        width: 150,
        hide: true,
        editable: false,
    },
    {
        field: 'verifyPassword',
        headerName: 'Enter password again*',
        inputType: 'password',
        hide: true,
        width: 150,
        editable: false,
    },
    {
        field: 'isAdmin',
        headerName: 'Admin',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.isAdmin ? "Yes" : "No"
    },
    {
        field: 'deactivate',
        headerName: 'Deactivated',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.deactivate ? "Yes" : "No"
    },
];

export default columns;