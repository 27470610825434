import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { customerStructure } from '../../data_formats';

const CustomerTable = (props) => {
  React.useEffect(() => {
    props.getAllCustomers()
  }, [])
  return (
    <>
      <Table
        title={"Customers"}
        pageTitle={"Customer"}
        columns={customerStructure}
        rows={props.customers.customers}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"customerStructure"}
        dataKey={"customers"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(CustomerTable);