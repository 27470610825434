import { ENABLE_LODER, DISABLE_LODER } from "./types";

export const enableLoder = () =>{
    return function(dispatch){
        dispatch({type: ENABLE_LODER})
    }
}

export const disableLoder = () =>{
    return function(dispatch){
        dispatch({type: DISABLE_LODER})
    }
}