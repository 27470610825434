import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";
import { transactionsVoucher } from '../../data_formats';

const TransactionVouchersTable = (props) => {
  React.useEffect(() => {
    let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
    let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

    if ([2].includes(priority)) {
      props.getTransactionByProvider(user.id)
    } else {
      props.getAllTransactionsVoucher()
    }
  }, [])
  
  // React.useEffect(() => {
  //     props.getAllTransactionsVoucher()
  // }, [])

  console.log("props.transactionsVoucher.transactionsVoucher",props.transactionsVoucher.transactionsVoucher)
  
  return (
    <>
      <Table
        title={"Redeemed vouchers"}
        pageTitle={"Redeemed vouchers"}
        columns={transactionsVoucher}
       //rows={props.transactions.transactions}
        rows={props.transactionsVoucher.transactionsVoucher}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"transactionsVoucher"}
        dataKey={"transactions"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(TransactionVouchersTable);