import {combineReducers} from 'redux';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import categoryReducer from './categoryReducer';
import providerReducer from './providerReducer';
import voucherReducer from './voucherReducer';
import productReducer from './productReducer';
import staticReducer from './staticReducer';
import transactionReducer from './transactionReducer';
import notificationReducer from './notificationReducer';
import transactionsVoucher from './transactionVoucherReducer';
import loderReducer from './loderReducer';

const rootReducer = combineReducers({
  users: userReducer,
  customers: customerReducer,
  providers: providerReducer,
  vouchers: voucherReducer,
  products: productReducer,
  static: staticReducer,
  transactions: transactionReducer,
  transactionsVoucher: transactionsVoucher,
  categorys:categoryReducer,
  notifications:notificationReducer,
  loder:loderReducer,
});

export default rootReducer;
