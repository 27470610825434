import {
  GET_ALL_TRANSACTIONS_VOUCHERS,
  SEARCH_TRANSACTIONS_VOUCHERS
} from '../actions/types';

const initialState = {
  transactionsVoucher: [],
  copyTransactionsVoucher: [],
  data: []
};

const transactionsVoucher = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case GET_ALL_TRANSACTIONS_VOUCHERS:
      return { ...state, transactionsVoucher: payload, copyTransactionsVoucher: payload };

    case  SEARCH_TRANSACTIONS_VOUCHERS:
      if(payload.length <= 0){
        return { ...state, transactionsVoucher: state.copyTransactionsVoucher }
      }

      const regex = new RegExp(payload, 'i')
      let filteredTransactionsVoucher = []
      state.copyTransactionsVoucher.forEach(e => {
        if (regex.test(e.id)) {
          filteredTransactionsVoucher.push(e)
        }
      })

      return { ...state, transactionsVoucher: filteredTransactionsVoucher }

    default:
      return state;
  }
};

export default transactionsVoucher ;
