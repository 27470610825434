const columns = [
    {
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 240
    },
    {
        field: '_products',
        headerName: 'Products',
        inputType: 'text',
        width: 240,
        valueGetter: (params) => {
            let products = []
            params.row._products.forEach(e => {
                products.push(e._product.name)
            });
            return products.join(", ")
        }
    },
    {
        field: '_provider',
        headerName: 'Provider Id',
        inputType: 'text',
        width: 240,
        isDisabled: false,
        hideInput: false
    },
    {
        field: '_customer',
        headerName: 'Customer Id',
        inputType: 'text',
        width: 240,
        isDisabled: false,
        hideInput: false
    },
    {
        field: 'timestamp',
        headerName: 'Purchased date',
        inputType: 'text',
        width: 400,
        editable: false,
        valueGetter: (params) => {
            // console.log(Number(params.row.timestamp))
            return (new Date(Number(params.row.timestamp)))
        }
    },
    {
        field: 'totalPrice',
        headerName: 'Total Price',
        inputType: 'text',
        width: 180
    },
    {
        field: 'deliveryCost',
        headerName: 'Delivery Cost',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'paymentMode',
        headerName: 'Payment Mode',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'paypalTranasctionId',
        headerName: 'Paypal Tranasction Id',
        inputType: 'text',
        width: 180,
        editable: false,
    }
];

export default columns;