import {
    GET_ALL_CATEGORY,
    GET_ONE_CATEGORY
  } from '../actions/types';
  
  const initialState = {
    categorys: [],
    copycategorys: [],
    data: []
  };
  
  const categoryReducer = (state = initialState, action) => {
    const payload = action.payload
 
    switch (GET_ALL_CATEGORY) {
      case GET_ALL_CATEGORY:
        return { ...state, categorys: payload, copycategorys: payload };
  
      case GET_ONE_CATEGORY:
        return { ...state, data: payload };
  
      default:
        return state;
    }
  };
  
  export default categoryReducer;
  