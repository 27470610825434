import {
  GET_ALL_PRODUCTS,
  GET_ONE_PRODUCT,
  SEARCH_PRODUCTS
} from '../actions/types';

const initialState = {
  products: [],
  copyProducts: [],
  data: []
};

const customerReducer = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return { ...state, products: payload, copyProducts: payload };

    case GET_ONE_PRODUCT:
      let provider = {
        providerName: (payload[0]._provider || {}).providerName || "",
        pid: (payload[0]._provider || {}).id || ""
      }

      payload[0] = {
        ...payload[0],
        ...provider
      }
      return { ...state, data: payload };

    case SEARCH_PRODUCTS:
      if(payload.length <= 0){
        return { ...state, products: state.copyProducts }
      }

      const regex = new RegExp(payload, 'i')
      let filteredProducts = []
      state.copyProducts.forEach(e => {
        if (regex.test(e.name)) {
          filteredProducts.push(e)
        }
      })

      return { ...state, products: filteredProducts }

    default:
      return state;
  }
};

export default customerReducer;
