import {
    GET_ALL_NOTIFICATION,
  } from './types';
  
  import axios from 'axios';
  const URL = process.env.REACT_APP_API_URL;
  
  const handlePost = (body, endpoint, headers = {}) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    headers = {
      ...headers,
      "x-request-token": token
    }
    return new Promise((resolve, reject) => {
      axios.post(`${URL}${endpoint}`, body, {
        headers
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  
  }
  
  const handleGet = (endpoint, headers = {}) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    headers = {
      ...headers,
      "x-request-token": token
    }
    return new Promise((resolve, reject) => {
      axios.get(`${URL}${endpoint}`, {
        headers
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
 
  export const getAllnotification = (data=[]) => {
    let pid = localStorage.getItem("uid");
   
    pid=JSON.parse(pid);
    console.log(pid.user);
    return function(dispatch) {
      handleGet('/getnotification?pid='+pid.user.id)
      .then(res => {
        console.log(res.data)
        dispatch({ type: GET_ALL_NOTIFICATION, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
    }
  };
  

 