import {
  GET_ALL_CUSTOMERS,
  GET_ONE_CUSTOMER,
  SEARCH_CUSTOMERS,
  DEACTIVATE_CUSTOMER
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const searchCustomers = (name) => {
  return { type: SEARCH_CUSTOMERS, payload: name }
};

export const getAllCustomers = (data=[]) => {
  return function(dispatch) {
    handleGet('/users/getAllUsers')
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ALL_CUSTOMERS, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const getOneCustomer = (data) => {
  return function(dispatch) {
    handleGet(`/users/getOneUser/${data}`)
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ONE_CUSTOMER, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const updateCustomer = (data, headers = {}) => {
  return function(dispatch) {
    handlePost(data, `/users/updateUser`, headers)
    .then(res => {
      console.log(res.data)
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const addCustomer = (data, headers = {}) => {
  return function(dispatch) {
    handlePost(data, `/users/add_user`, headers)
    .then(res => {
      alert("Added Customer")
      console.log(res.data)
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const deactivateCustomer = (data) => {
  return { type: DEACTIVATE_CUSTOMER, payload: { data } };
};