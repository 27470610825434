import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import productStructure from '../../data_formats/productTable';

const ProductTable = (props) => {

  const [updatePage, setUpdatePage] = React.useState(false);

  React.useEffect(() => {
    let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
    let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

    if ([2].includes(priority)) {
      props.getProductsByProvider(user.id)
    } else {
      props.getAllProducts()
    }
  }, [updatePage])

  const handleDeactivateProduct = (data) => {
    props.updateProduct(data, { 'Content-Type': 'application/json' })

    setUpdatePage(!updatePage)
  }

  return (
    <>
      <Table
        title={"Products"}
        pageTitle={"Product"}
        columns={productStructure(handleDeactivateProduct)}
        rows={props.products.products}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"productStructure"}
        dataKey={"products"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(ProductTable);