import {
  GET_STATICDATA,
  UPDATE_STATICDATA,
  DISABLE_LODER
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const getStaticData = (data = []) => {
  return function (dispatch) {
    handleGet('/staticcontents/getContents')
      .then(res => {
        console.log(res.data)
        dispatch({ type: GET_STATICDATA, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const saveStaticData = (data, headers = {}) => {
  return function (dispatch) {
    handlePost(data, `/staticcontents/saveContent`, headers)
      .then(res => {
        dispatch({ type: DISABLE_LODER });
        console.log("this is res--->", res)
        console.log("this is res.data --->", res.data)
        window.location.reload()
        alert("Saved data")
      })
      .catch(e => {
        dispatch({ type: DISABLE_LODER });
        console.log(e)
      })
  }
};