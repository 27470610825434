import { useState, useEffect } from 'react';
import { Button, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Box, Paper, Fab, DialogContentText } from '@mui/material';

import AddIcon from "@mui/icons-material/Add";

let values = []
const id = "adds"
const structure = {
  headerName: "Upload CSV",
  field: "csvFile",
  fileType: ["csv"]
}

const handleSubmit = (props) => {
  console.log("values", values);
  let keys = Object.keys(values)

  if (!keys.includes("pid")) {
    alert("Please enter Provider ID")
    return false
  }

  if (!keys.includes("csvFile")) {
    alert("Please choose csv file")
    return false
  }

  var formData = new FormData();

  keys.forEach(e => {
    formData.append(e, values[e])
  });

  props.addProductByCsv(formData, { 'Content-Type': 'multipart/form-data' })
  delete values.csvFile

  return true
}

const FormDialog = (props) => {
  const [filename, setFilename] = useState({});
  const [item, setItem] = useState("")
  let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
  let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

  const handleClose = () => {
    if ([1].includes(priority)) {
      values = {}
      setItem("")
    }
    setFilename({})
    props.handleOpenClose(false);
  };

  useEffect(() => {
    values = {}
    if ([2].includes(priority)) {
      setItem(user.id)
      values = {
        ...values,
        pid: user.id
      }
    }
  }, [])

  return (
    <div className='product-modal'>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Upload Product Lists</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="adds_pid"
            className="adds_pid"
            name="adds_pid"
            label="Provider ID"
            type="text"
            disabled={[2].includes(priority)}
            value={item}
            onChange={(e) => {
              setItem(e.currentTarget.value)
              values = {
                ...values,
                pid: e.currentTarget.value
              }
            }}
            sx={{
              width: "20em"
            }}
            variant="outlined"
          />

          <div className={`${id}_${structure.field}`} style={{ marginTop: "2em", }}>
            <Stack direction="row" spacing={2}>
              <label htmlFor={`${id}_${structure.field}`}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name={`${id}_${structure.field}`}
                  className={`${id}_${structure.field}`}
                  id={`${id}_${structure.field}`}
                  autoComplete='false'
                  onChange={(e) => {
                    let file = document.getElementById(e.currentTarget.id)
                    let nameArr = ((file.files[0] || {}).name || "").split(".")
                    let ext = nameArr[nameArr.length - 1]

                    if (!(structure.fileType || []).includes(ext)) {
                      alert("Please Upload these files -> " + structure.fileType.join(", "))
                      return false
                    }

                    let key = structure.field
                    values = { ...values, [key]: file.files[0] }
                    var tmppath = URL.createObjectURL(e.target.files[0]);
                    console.log(tmppath)
                    setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                  }}
                />

                <Fab
                  color="secondary"
                  component="span"
                  aria-label="add"
                  variant="extended"
                >
                  <AddIcon />{structure.headerName}
                </Fab>
              </label>
              <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={(e) => {
            let res = handleSubmit(props)
            if (res)
              handleClose()
          }}>Add Product</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog 