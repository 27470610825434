import {
  GET_ALL_VOUCHERS,
  GET_ONE_VOUCHER,
  SEARCH_VOUCHERS
} from '../actions/types';

const initialState = {
  vouchers: [],
  copyVouchers: [],
  data: []
};

const voucherReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case GET_ALL_VOUCHERS:
      return { ...state, vouchers: payload, copyVouchers: payload };

    case GET_ONE_VOUCHER:
      let provider = {
        providerName: (payload[0]._provider || {}).providerName || "",
        pid: (payload[0]._provider || {}).id || ""
      }

      payload[0] = {
        ...payload[0],
        ...provider
      }
      return { ...state, data: payload };

    case SEARCH_VOUCHERS:
      if(payload.length <= 0){
        return { ...state, vouchers: state.copyVouchers }
      }

      const regex = new RegExp(payload, 'i')
      let filteredVouchers = []
      state.copyVouchers.forEach(e => {
        if (regex.test(e.title)) {
          filteredVouchers.push(e)
        }
      })

      return { ...state, vouchers: filteredVouchers }

    default:
      return state;
  }
};

export default voucherReducer;
