// export const CURRENT_PAGE = "current_page";

export const GET_ONE_USER = "get_one_user";
export const GET_ALL_USERS = "get_all_users";
export const ADD_USER = "add_user";
export const UPDATE_USER = "update_user";
export const DELETE_USER = "delete_user";
export const SEARCH_USERS = "search_users";

export const GET_ONE_CATEGORY = "get_one_category";
export const GET_ALL_CATEGORY = "get_all_category";
export const ADD_CATEGORY = "add_category";
export const UPDATE_CATEGORY = "update_category";

export const GET_ONE_CUSTOMER = "get_one_customer";
export const GET_ALL_CUSTOMERS = "get_all_customers";
export const ADD_CUSTOMER = "add_customer";
export const UPDATE_CUSTOMER = "update_customer";
export const DEACTIVATE_CUSTOMER = "deactivate_customer";
export const SEARCH_CUSTOMERS = "search_customers";

export const GET_ONE_PROVIDER = "get_one_provider";
export const GET_ALL_PROVIDERS = "get_all_providers";
export const ADD_PROVIDER = "add_provider";
export const UPDATE_PROVIDER = "update_provider";
export const DEACTIVATE_PROVIDER = "deactivate_provider";
export const SEARCH_PROVIDERS = "search_providers";

export const GET_ONE_VOUCHER = "get_one_voucher";
export const SEARCH_VOUCHERS = "search_vouchers";
export const GET_ALL_VOUCHERS = "get_all_vouchers";
export const ADD_VOUCHER = "add_voucher";
export const UPDATE_VOUCHER = "update_voucher";
export const DEACTIVATE_VOUCHER = "deactivate_voucher";

export const GET_ONE_PRODUCT = "get_one_product";
export const GET_ALL_PRODUCTS = "get_all_products";
export const SEARCH_PRODUCTS = "search_products";
export const ADD_PRODUCT = "add_product";
export const DELETE_PRODUCT = "delete_product";
export const UPDATE_PRODUCT = "update_product";
export const DEACTIVATE_PRODUCT = "deactivate_product";

export const UPDATE_STATICDATA = "UPDATE_STATICDATA";
export const GET_STATICDATA = "GET_STATICDATA";

export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const SEARCH_TRANSACTIONS = "SEARCH_TRANSACTIONS";

export const GET_ALL_TRANSACTIONS_VOUCHERS = "GET_ALL_TRANSACTIONS_VOUCHERS";
export const SEARCH_TRANSACTIONS_VOUCHERS = "SEARCH_TRANSACTIONS_VOUCHERS";

export const GET_ALL_NOTIFICATION = "get_all_category";

export const ENABLE_LODER = "ENABLE_LODER";
export const DISABLE_LODER = "DISABLE_LODER";

export const ADDPRFIELD='ADDPRFIELD'
export const EDITPRFIELD='EDITPRFIELD'
export const DELETEPRFIELD='DELETEPRFIELD'