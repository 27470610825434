import { useState, useEffect } from "react";
import _ from 'underscore';
import { Button, Typography, TextareaAutosize } from "@mui/material"
import structures from '../../../data_formats';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../../store/MapToProps";
import Loader from "../../common/loder"

let values = {}
let fields = []

const handleSubmit = (props) => {

  console.log("values", values);

  let keys = Object.keys(values)

  console.log("length of the keys" , keys);
  console.log("values" , values);

  if (keys.length > 0) {
    props.enableLoder()
    props.saveStaticData(values)
  }else{
    alert("Please Change your content to save")
  }

}

const GenerateFields = (props) => {

  let handleReset;

  const GetField = ({ structure, id }) => {
    const [item, setItem] = useState({})

    handleReset = () => {
      console.log("reseting state")
      setItem({})
      values = {}
    }

    useEffect(() => {
      setItem({
        ...item,
        ...props.static.static[0]
      })
    }, [props.static.static])

    switch (structure.inputType) {
      case "text":
        return (
          <>
            <h3>{structure.headerName}</h3>
            <TextareaAutosize
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`}
              type={structure.inputType}
              label={structure.headerName}
              // placeholder={structure.headerName}
              autoComplete='false'
              maxRows={10}
              minRows={8}
              aria-label="maximum height"
              style={{
                marginBottom: "2em",
                width: "40em",
                fontSize: "20px",
                display: structure.hideInput ? "none" : ""
              }}
              value={item[structure.field] || ""}
              onChange={(e) => {
                let key = structure.field
                setItem({ ...item, [key]: e.currentTarget.value })
                values = { ...values, [key]: e.currentTarget.value }
              }}
              disabled={structure.isDisabled ? true : false}
            />
          </>
        )
      default:
        return (
          <></>
        )
    }
  }

  return (
    <>
      {structures.staticStructure.map((item, index) => (
        <div key={index}>
          <GetField structure={item} id={"add"} />
        </div>
      ))}
      <Loader />

      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
        }}
        onClick={(e) => {
          handleSubmit(props)
        }}
      >
        SAVE
      </Button>
      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
          marginLeft: "3em"
        }}
        onClick={(e) => {
          handleReset()
        }}
      >
        RESET
      </Button>
    </>
  )
}

const AddData = (props) => {

  useEffect(() => {
    values = {}
    fields = []

    if (props.static.static.length <= 0) {
      props.getStaticData()
    }

    structures[props.fieldKey].forEach((i) => {
      if (!i.hideInput)
        fields.push(i.field)
    })

  }, [])

  return (
    <>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        {`Add ${props.pageTitle}`}
      </Typography>

      <GenerateFields {...props} />
    </>
  )
}

export default connect(mapStateToProps, mapDispachToProps)(AddData);