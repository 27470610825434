import adminStructure from "./adminUsers"
import customerStructure from "./customers"
import categoryStructure from "./category"
import providerStructure from "./providers"
import voucherStructure from "./vouchers"
import productStructure from "./products"
import staticStructure from "./staticPage"
import transactionStructure from "./transactions"
import notificationStructure from "./notification"
import transactionsVoucher from './transactionsVoucher'

const structures = {
    adminStructure,
    customerStructure,
    providerStructure,
    voucherStructure,
    productStructure,
    staticStructure,
    transactionStructure,
    transactionsVoucher,
    categoryStructure,
    notificationStructure
}

export {
    adminStructure,
    customerStructure,
    providerStructure,
    voucherStructure,
    productStructure,
    staticStructure,
    transactionStructure,
    transactionsVoucher,
    categoryStructure,
    notificationStructure
}

export default structures