const columns = [
  {
    field: "id",
    headerName: "ID",
    inputType: "text",
    width: 240,
  },
  {
    field: "_voucher",
    headerName: "vouchers",
    inputType: "text",
    width: 240,
    valueGetter: (params) => {
      //  console.log('voucher title --->', params.row._voucher.title);

      return params.row._voucher.title;
    },
  },
  {
    field: "_provider",
    headerName: "Provider Id",
    inputType: "text",
    width: 240,
    isDisabled: false,
    hideInput: false,
  },
  {
    field: "_customer",
    headerName: "Customer Id",
    inputType: "text",
    width: 240,
    isDisabled: false,
    hideInput: false,
  },
  {
    field: "timestamp",
    headerName: "Redeem date",
    inputType: "text",
    width: 240,
    editable: false,
    valueGetter: (params) => {
    //   const timestamp = Number(params.row.timestamp) / 1000; // Ensure it's a number
    //   const dateObj = new Date(timestamp); // Convert to Date object

    //   // Extract day, month, and year
    //   const day = dateObj.getDate();
    //   const month = dateObj.getMonth() + 1; // Months are zero-based
    //   const year = dateObj.getFullYear();

      // Format the date as 'DD-MM-YYYY'
    //   return `${day}-${month}-${year}`;
    return params.row.redeemedTimestamp
    },
  },
  {
    field: "status",
    headerName: "Status",
    inputType: "text",
    width: 240,
    valueGetter: (params) => {
      //  console.log('voucher title --->', params.row._voucher.title);

      return params.row.status;
    },
  },
  // {
  //     field: 'deliveryCost',
  //     headerName: 'Delivery Cost',
  //     sortable: true,
  //     inputType: 'text',
  //     width: 180,
  //     isDisabled: false
  // },
  // {
  //     field: 'paymentMode',
  //     headerName: 'Payment Mode',
  //     inputType: 'text',
  //     width: 150,
  //     editable: false,
  // },
  // {
  //     field: 'paypalTranasctionId',
  //     headerName: 'Paypal Tranasction Id',
  //     inputType: 'text',
  //     width: 180,
  //     editable: false,
  // }
];

export default columns;
