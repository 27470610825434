const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 240,
        isDisabled: true,
        hideInput: true
    },
    { 
        field: 'pid',
        headerName: 'Provider ID',
        inputType: 'text',
        width: 240,
        isDisabled: false,
        hideInput: false,
        valueGetter: (params) => (params.row._provider || {})._id || ""
    },
    { 
        field: 'providerName',
        headerName: 'Provider Name',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true,
        valueGetter: (params) => (params.row._provider || {}).providerName || ""
    },
    {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    // {
    //     field: 'voucherTaken',
    //     headerName: 'Voucher Taken',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    // {
    //     field: 'voucherRedeemed',
    //     headerName: 'Voucher Redeemed',
    //     inputType: 'text',
    //     width: 150,
    //     editable: false,
    // },
    {
        field: 'startDate',
        headerName: 'Start Date',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        inputType: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'shortDescription',
        headerName: 'Short Description',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'longDescription',
        headerName: 'Long Description',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'activeImage',
        headerName: 'Active Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'inactiveImage',
        headerName: 'Inactive Image',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'redemptionBarcode',
        headerName: 'Redemption Barcode',
        inputType: 'file',
        width: 150,
        editable: false,
        fileType: ["jpg", "jpeg", "png"]
    },
    {
        field: 'deactivate',
        headerName: 'Deactivated',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.deactivate ? "Yes" : "No"
    },

    {
        field: 'isUnique',
        headerName: 'Only one voucher for one user.',
        inputType: 'switch',
        width: 150,
        editable: true,
        valueGetter: (params) => params.row.isUnique ? "Yes" : "No"
    },
    {
        field: 'iswelcome',
        headerName: 'Welcome Page',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.iswelcome ? "Yes" : "No"
    },
];

export default columns;