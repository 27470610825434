const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 180,
        isDisabled: true,
        hideInput: true
    },
    {
        field: 'name',
        headerName: 'Full name',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'region',
        headerName: 'Region',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'email',
        headerName: 'Email',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    {
        field: 'password',
        headerName: 'Password',
        inputType: 'password',
        width: 150,
        hide: true,
        editable: false,
    },
    {
        field: 'verifyPassword',
        headerName: 'Enter password again',
        inputType: 'password',
        hide: true,
        width: 150,
        editable: false,
    },
    {
        field: 'mobile',
        headerName: 'Mobile',
        inputType: 'text',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'deliveryNote',
        headerName: 'Delivery note',
        inputType: 'text',
        width: 150,
        editable: false,
    },
    // {
    //     field: 'picture',
    //     headerName: 'Picture Location',
    //     inputType: 'file',
    //     width: 150,
    //     editable: false,
    // },
    {
        field: 'emailVerified',
        headerName: 'Email verified',
        inputType: 'switch',
        width: 120,
        isDisabled: true,
        valueGetter: (params) => params.row.emailVerified ? "Yes" : "No"
    },
    {
        field: 'deactivate',
        headerName: 'Deactivated',
        inputType: 'switch',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.deactivate ? "Yes" : "No"
    },
];

export default columns;