import axios from 'axios'
const URL = process.env.REACT_APP_API_URL;



///......for Post method Api.....
export const postApiCall = async (endpoint, data, params) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  console.log('Post Api Hit', endpoint)
  let headers = {
    'Content-Type': 'application/json',
    "x-request-token": token
  }
  const response = await axios.post(`${URL}${endpoint}`, data, {
    params,
    headers,
  })
  return response
}

///......for Post method Api in Form data.....
export const postApiCallFormData = async (endpoint, data, params) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  let headers = {
    'Content-Type': 'multipart/form-data',
    "x-request-token": token,
  }
  console.log('Post Api Hit FormData', endpoint)
  const response = await axios.post(`${URL}${endpoint}`, data, {
    params,
    headers,
  })
  return response
}

// export async function postApiCall(url, data, params) {
//     const token = await AsyncStorage.getItem('token');
//     const headers = token == null ? {
//         Accept: 'application/json',
//         'Content-Type': 'multipart/form-data'
//     } : {
//         Accept: 'application/json',
//         'Content-Type': 'multipart/form-data',
//         'Authorization': `Bearer ${token}`
//     };
//     return axios.post(`${base_url}${url}`, data, { params, headers });
// }


////......from Delete method Api.......
export const deleteApiCall = async (url, data, params) => {
  const profileData = JSON.parse(localStorage.getItem('data'))
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${profileData.token}`,
  }
  // return axios.delete(`${base_url}${url}`, payload)
  const response = await axios.delete(`${URL}${url}`, data, {
    params,
    headers,
  })
  return response
}
