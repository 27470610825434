import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { adminStructure } from '../../data_formats';

const AdminUserTable = (props) => {
  React.useEffect(() => {
    props.getAllUsers()
  }, [])
  return (
    <>
      <Table
        title={"Admins"}
        pageTitle={"Admin"}
        columns={adminStructure}
        rows={props.users.users}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"adminStructure"}
        dataKey={"users"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(AdminUserTable);