import {
  GET_ALL_VOUCHERS,
  GET_ONE_VOUCHER,
  DEACTIVATE_VOUCHER,
  SEARCH_VOUCHERS
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const searchVouchers = (name) => {
  return { type: SEARCH_VOUCHERS, payload: name }
};

export const getAllVouchers = (data=[]) => {
  return function(dispatch) {
    handleGet('/vouchers/getAllVoucher')
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ALL_VOUCHERS, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const getAllVoucherByProvider = (pid) => {
  return function(dispatch) {
    handleGet('/vouchers/getAllVoucherByProviderAdmin?pid='+pid)
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ALL_VOUCHERS, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const getOneVoucher = (data) => {
  return function(dispatch) {
    handleGet(`/vouchers/getOneVoucher1/${data}`)
    .then(res => {
      console.log(res.data)
      dispatch({ type: GET_ONE_VOUCHER, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const updateVoucher = (data, headers = {}) => {
  return function(dispatch) {
    handlePost(data, `/vouchers/updateVoucher`, headers)
    .then(res => {
      alert("Updated Voucher")
      console.log(res.data)
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const addVoucher = (data, headers = {}) => {
  return function(dispatch) {
    handlePost(data, `/vouchers/add_voucher`, headers)
    .then(res => {
      alert("Added Voucher")
      console.log(res.data)
    })
    .catch(e => {
      console.log(e)
    })
  }
};

export const deactivateVoucher = (data) => {
  return { type: DEACTIVATE_VOUCHER, payload: { data } };
};