import {
  GET_ALL_TRANSACTIONS_VOUCHERS,
  SEARCH_TRANSACTIONS_VOUCHERS
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const searchTransactionVouchers = (id) => {
  return { type:  SEARCH_TRANSACTIONS_VOUCHERS, payload: id }
};

export const getAllTransactionsVoucher = (data=[]) => {
  return function(dispatch) {
    handleGet('/vouchers/getAllVoucherTransactions')
    .then(res => {
      console.log('transaction voucher ==>',res.data)
      dispatch({ type: GET_ALL_TRANSACTIONS_VOUCHERS, payload: res.data.result })
    })
    .catch(e => {
      console.log(e)
    })
  }
};