import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";

import { transactionStructure } from '../../data_formats';

const TransactionTable = (props) => {
  React.useEffect(() => {
    let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
    let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

    if ([2].includes(priority)) {
      props.getTransactionByProvider(user.id)
    } else {
      props.getAllTransactions()
    }
  }, [])
  return (
    <>
      <Table
        title={"Transactions"}
        pageTitle={"Transactions"}
        columns={transactionStructure}
        rows={props.transactions.transactions}
        rowsPerPage={50}
        pageSize={10}
        fieldKey={"transactionStructure"}
        dataKey={"transactions"}
        {...props}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispachToProps)(TransactionTable);