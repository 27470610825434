import { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { TextField, Switch, Button, FormControlLabel, Fab, Typography, Stack, Paper, Box,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from 'react-router-dom';
import structures from '../../data_formats';
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";
import axios from 'axios';
import moment from 'moment'

let values = {}

const GetField = ({ structure, id, data }) => {
  const [filename, setFilename] = useState({});
  const [item, setItem] = useState({})
  let currentDate = moment(new Date()).format('YYYY-MM-DD');
  
  useEffect(() => {
    console.log("data 2", data)
    if (data) {
      setItem({ ...item, ...data })
      values = {
        ...values,
        id: data.id
      }
    }
  }, [data])
  useEffect(() => {
    if(data2.length<=0 && structure.inputType=='select'){
    axios
      .get(process.env.REACT_APP_API_URL + "/getcategory?type="+structure.data)
      .then((res) => {
        console.log("res", res.data.result)
        setData(res.data.result)
      })
    }
  }, [data])
  const [data2, setData] = useState([])

 
  switch (structure.inputType) {
    case "text":
      return (
        <TextField
          className={`${id}_${structure.field}`}
          id={`${id}_${structure.field}`}
          variant="outlined"
          type={structure.inputType}
          label={structure.headerName}
          sx={{
            marginBottom: "2em",
            width: "30em"
          }}
          value={item[structure.field] || ""}
          onChange={(e) => {
            let key = structure.field
            setItem({ ...item, [key]: e.currentTarget.value })
            values = { ...values, [key]: e.currentTarget.value }
            // values.append(key, e.currentTarget.value)
          }}
          disabled={structure.isDisabled ? true : false}
        />
      )
      case "select":
        return (
         
          <FormControl style={{width:'50%',marginBottom:10}}>
            
<InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
         value={item[structure.field] || ""}
          onChange={(e) => {
            
            let key = structure.field
            setItem({ ...item, [key]: e.target.value })
            values = { ...values, [key]: e.target.value }
          }}
         
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`} >
             {data2.map((ans,i)=>
             
<MenuItem key={i+1} value={ans.Name}>{ans.Name}</MenuItem>
            )}
</Select>
</FormControl>
        )
    case "date":
      return (
        <TextField
          className={`${id}_${structure.field}`}
          id={`${id}_${structure.field}`}
          name={`${id}_${structure.field}`}
          type={structure.inputType}
          label={structure.headerName}
          autoComplete='false'
          variant="outlined"
          sx={{
            marginBottom: "2em",
            width: "30em",
            display: structure.hideInput ? "none" : ""
          }}
          value={item[structure.field] || currentDate}
          onChange={(e) => {
            let key = structure.field
            setItem({ ...item, [key]: e.currentTarget.value })
            values = { ...values, [key]: e.currentTarget.value }
          }}
          disabled={structure.isDisabled ? true : false}
        />
      )
    case "file":
      return (
        <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
          <Stack direction="row" spacing={2}>
            <label htmlFor={`${id}_${structure.field}`}>
              <input
                style={{ display: "none" }}
                name={`${id}_${structure.field}`}
                type="file"
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                onChange={(e) => {
                  let file = document.getElementById(e.currentTarget.id)
                  let nameArr = ((file.files[0] || {}).name || "").split(".")
                  let ext = nameArr[nameArr.length - 1]

                  if (!(structure.fileType || []).includes(ext)) {
                    alert("Please Upload these files -> " + structure.fileType.join(", "))
                    return false
                  }

                  let key = structure.field
                  values = { ...values, [key]: file.files[0] }
                  var tmppath = URL.createObjectURL(e.target.files[0]);
                  console.log(tmppath)
             
                  setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                }}
              />

              <Fab
                color="secondary"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {structure.headerName}
              </Fab>
            </label>
            {(item[structure.field] || filename[structure.field + "_preview"]) ? (
              <>
              <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  height: 120,
                  width: 120
                },
              }}
            >
              <Paper elevation={3} sx={{ position: "relative" }}>
                <CancelIcon
                  onClick={(e) => {
                    // alert("clicked")
                    setFilename({
                      ...filename,
                      [structure.field + "_preview"]: null
                    })
                    setItem({
                      ...item, [structure.field]: null
                    })
                    values[structure.field] = {}
                  }}
                  sx={{
                    right: "-20px",
                    top: "-18px",
                    position: "absolute"
                  }} />
                {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? <img style={{ borderRadius: "2px" }} height="120" width="120" src={filename[structure.field + "_preview"] || process.env.REACT_APP_WEB_URL + item[structure.field]} /> : <></>}
              </Paper>
            </Box>
            </>) : (<></>)}
            {(filename[structure.field + "_preview"] !== null || item[structure.field] !== null) ? <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography> : <></>}
          </Stack>
        </div>
      )
    case "switch":
      return (
        <FormControlLabel
          control={<Switch
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            checked={(item[structure.field] ? true : false)}
            onChange={(e) => {
              let key = structure.field
              setItem({ ...item, [key]: e.currentTarget.checked })
              values = { ...values, [key]: e.currentTarget.checked }
              // values.append(key, e.currentTarget.checked)
            }}
          />}
          label={structure.headerName}
          disabled={structure.isDisabled ? true : false}
          sx={{
            marginBottom: "2em",
            width: "30em"
          }}
        />
      )
    default:
      return (
        <></>
      )
  }
}

const getData = (props, category, id) => {
  switch (category) {
    case "Admin":
      props.getOneUser(id)
      break;
    case "Customer":
      props.getOneCustomer(id)
      break;
    case "Provider":
      props.getOneProvider(id)
      break;
    case "Voucher":
      props.getOneVoucher(id)
      break;
    case "Product":
      props.getOneProduct(id)
      break;
      case "Category":
        props.getOnecategory(id)
        break;
    default:
      break;
  }
}

const handleSubmit = (props, category, id) => {
  console.log("values", values);

  var formData = new FormData();
  let keys = Object.keys(values)

  keys.forEach(e => {
    formData.append(e, values[e])
  });

  // console.log(formData)

  switch (category) {
    case "Admin":
      props.updateUser(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Customer":
      props.updateCustomer(formData, { 'Content-Type': 'multipart/form-data' })
      break
    case "Provider":
      props.updateProvider(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Category":
      props.updateCatagory(formData, { 'Content-Type': 'multipart/form-data' })
      break
    case "Voucher":
      props.updateVoucher(formData, { 'Content-Type': 'multipart/form-data' })
      break
    case "Product":
      props.updateProduct(formData, { 'Content-Type': 'multipart/form-data' })
      break
    default:
      break;
  }
}

const UpdateData = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [isFetching, setFetching] = useState(false);

  let deleteProduct= (id)=>{
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    let headers = {
      "x-request-token": token
    }
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/products/deleteOneProduct`, {id: id}, {headers})
      .then((response) => {
        setFetching(false);
        const goBack = () => {
          navigate('/products'); 
        };
        goBack();
      })
      .catch((error) => {
        alert(error)
        console.log("Error deleting product ", error);
      });
    
  }

  const deletCustomer= (id) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    let headers = {
      "x-request-token": token
    }
    console.log("headers",headers)
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/deleteOneUser`, {id: id}, {headers})
      .then((response) => {
        setFetching(false);
        const goBack = () => {
          navigate('/customers'); 
        };
        goBack();
      })
      .catch((error) => {
        alert(error)
        console.log("Error fetching report data ", error);
      });
  }

  const deleteCategory= (id) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    let headers = {
      "x-request-token": token
    }
    setFetching(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/deletecategory/${id}`, {headers})
      .then((response) => {
        setFetching(false);
        const goBack = () => {
          navigate('/Categorys'); 
        };
        goBack();
      })
      .catch((error) => {
        alert(error)
        console.log("Error deleting category ", error);
      });
  }

  const deleteVoucher= (id) => {
    let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
    let headers = {
      "x-request-token": token
    }
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/vouchers/deleteOneVoucher/${id}`, {headers})
      .then((response) => {
        setFetching(false);
        const goBack = () => {
          navigate('/Categorys'); 
        };
        goBack();
      })
      .catch((error) => {
        alert(error)
        console.log("Error deleting category ", error);
      });
  }

  useEffect(() => {
    values = {}
    getData(props, location.state.pageTitle, location.state.id)
  }, [])

  return (
    <>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        {`Update ${location.state.pageTitle}`}
        {
          location.state.pageTitle == "Customer" && <DeleteIcon
            style={{ marginRight: "5px", marginLeft: "5px", color: 'red' }}
            onClick={() => {
              deletCustomer(location.state.id);
            }}
          />
        }
        {
          location.state.pageTitle == "Product" && <DeleteIcon
            style={{ marginRight: "5px", marginLeft: "5px", color: 'red' }}
            onClick={() => {
              deleteProduct(location.state.id);
            }}
          />
        }
        {
          location.state.pageTitle == "Category" && <DeleteIcon
            style={{ marginRight: "5px", marginLeft: "5px", color: 'red' }}
            onClick={() => {
              deleteCategory(location.state.id);
            }}
          />
        }
        {
          location.state.pageTitle == "Voucher" && <DeleteIcon
            style={{ marginRight: "5px", marginLeft: "5px", color: 'red' }}
            onClick={() => {
              deleteVoucher(location.state.id);
            }}
          />
        }

      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {structures[location.state.fieldKey].map((item, index) => (
        <div key={index}>
          {
            props[location.state.dataKey]?.categorys &&
            <>
            <GetField structure={item} id={location.state.id} data={props[location.state.dataKey]?.categorys[0] || {}} />
            </> 
          }
          {
            props[location.state.dataKey]?.data[0] &&
            <>
            <GetField structure={item} id={location.state.id} data={props[location.state.dataKey]?.data[0] || {}} />
            </>
          }
          
        </div>
      ))}

      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
        }}
        onClick={(e) => {
          handleSubmit(props, location.state.pageTitle, location.state.id)
        }}
      >
        Update
      </Button>
    </>
  )
}

export default connect(mapStateToProps, mapDispachToProps)(UpdateData);