import {
  GET_ALL_PRODUCTS,
  GET_ONE_PRODUCT,
  DEACTIVATE_PRODUCT,
  SEARCH_PRODUCTS,
  DELETE_PRODUCT
} from './types';

import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

const handlePost = (body, endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}${endpoint}`, body, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })

}

const handleGet = (endpoint, headers = {}) => {
  let token = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).token : ""
  headers = {
    ...headers,
    "x-request-token": token
  }
  return new Promise((resolve, reject) => {
    axios.get(`${URL}${endpoint}`, {
      headers
    })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const searchProducts = (name) => {
  return { type: SEARCH_PRODUCTS, payload: name }
};

export const getAllProducts = (data = []) => {
  return function (dispatch) {
    handleGet('/products/getAllProduct')
      .then(res => {
        console.log(res.data)
        dispatch({ type: GET_ALL_PRODUCTS, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const deleteProduct = (data) => {
  return function (dispatch) {
    handlePost(`/products/deleteOneProduct/`, data)
      .then(res => {
        console.log(res.data)
        dispatch({ type: DELETE_PRODUCT, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
  }
}

export const getProductsByProvider = (pid) => {
  return function (dispatch) {
    handleGet('/products/getProductsByProviderAdmin?pid=' + pid)
      .then(res => {
        console.log(res.data)
        dispatch({ type: GET_ALL_PRODUCTS, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const getOneProduct = (data) => {
  return function (dispatch) {
    handleGet(`/products/getOneProduct/${data}`)
      .then(res => {
        console.log(res.data)
        dispatch({ type: GET_ONE_PRODUCT, payload: res.data.result })
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const updateProduct = (data, headers = {}) => {
  console.log("DATA :::", data, headers)
  return function (dispatch) {
    handlePost(data, `/products/updateProduct`, headers)
      .then(res => {
        alert("Updated Product")
        console.log(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const addProduct = (data, headers = {}) => {
  return function (dispatch) {
    handlePost(data, `/products/add_product`, headers)
      .then(res => {
        alert("Added Product")
        console.log(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
};

export const addProductByCsv = (data, headers = {}) => {
  return function (dispatch) {
    handlePost(data, `/products/add_products_csv`, headers)
      .then(res => {
        if (res.data.status) {
          console.log(res.data)
          alert("Added Product")
        } else {
          alert(res.data.msg)
        }

      })
      .catch(e => {
        console.log(e)
        alert(e)
      })
  }
};

export const deactivateProduct = (data) => {
  return { type: DEACTIVATE_PRODUCT, payload: { data } };
};