import {
  GET_STATICDATA,
  UPDATE_STATICDATA
} from '../actions/types';

const initialState = {
  static: [],
  copyStatic: [],
  data: []
};

const staticReducer = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case GET_STATICDATA:
      return { ...state, static: payload, copyStatic: payload };

    case UPDATE_STATICDATA:
      return { ...state, static: payload, copyStatic: payload };

    default:
      return state;
  }
};

export default staticReducer;
